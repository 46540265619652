import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chess } from 'chess.js';
import ReactGA from 'react-ga';

import Board from './Board copy';
import StandingsRow from './StandingsRow';
import Tournament from './Tournament';
import TournamentParent from './TournamentParent';
import KnockoutTournament from './KnockoutTournament';

import wccProbs1 from '../WCCProbs2023/wccprobs1.txt';
import wccProbs2 from '../WCCProbs2023/wccprobs2.txt';
import wccProbs3 from '../WCCProbs2023/wccprobs3.txt';
import wccProbs4 from '../WCCProbs2023/wccprobs4.txt';
import wccProbs5 from '../WCCProbs2023/wccprobs5.txt';
import wccProbs6 from '../WCCProbs2023/wccprobs6.txt';
import wccProbs7 from '../WCCProbs2023/wccprobs7.txt';
import wccProbs8 from '../WCCProbs2023/wccprobs8.txt';
import wccProbs9 from '../WCCProbs2023/wccprobs9.txt';
import wccProbs10 from '../WCCProbs2023/wccprobs10.txt';
import wccProbs11 from '../WCCProbs2023/wccprobs11.txt';
import wccProbs12 from '../WCCProbs2023/wccprobs12.txt';
import wccProbs13 from '../WCCProbs2023/wccprobs13.txt';
import wccProbs14 from '../WCCProbs2023/wccprobs14.txt';

import graph from '../Images/wcc2023graphfinal.png';

const depth = 30;

const wccTimeControl = [
    {
        moves: 40,
        time: 120 * 60,
        increment: 0,
    },
    {
        moves: 20,
        time: 60 * 60,
        increment: 0,
    },
    {
        moves: Infinity,
        time: 15 * 60,
        increment: 30,
    },
]

const worldCupClassical = [
    {
        moves: 40,
        time: 90 * 60,
        increment: 30,
    },
    {
        moves: Infinity,
        time: 30 * 60,
        increment: 30,
    },
]
const worldCupTB_25min= [
    {
        moves: Infinity,
        time: 25 * 60,
        increment: 10,
    },
]
const worldCupTB_10min = [
    {
        moves: Infinity,
        time: 10 * 60,
        increment: 10,
    },
]
const worldCupTB_5min = [
    {
        moves: Infinity,
        time: 5 * 60,
        increment: 3,
    },
]
const worldCupTB_3min = [
    {
        moves: Infinity,
        time: 3 * 60,
        increment: 2,
    },
]


const miscTournamentData = {
    "FIDE World Chess Championship 2023": {
        order: 2,
        currentRound: 14,
        type: 'match',
        comingSoon: false,
        display: true,
        playerInfo: [
            {
                name: "Nepo",
                score: 6.5,
            },
            {
                name: "Ding",
                score: 6.5,
            }
        ],
        playerColors: {
            "Nepo": "#7f6fff",
            "Ding": "#ff6f6f",
        },
        probInfo: [
            {
                data: wccProbs1,
                N: 10000,
            },
            {
                data: wccProbs2,
                N: 10000
            },
            {
                data: wccProbs3,
                N: 10000
            },
            {
                data: wccProbs4,
                N: 10000
            },
            {
                data: wccProbs5,
                N: 10000
            },
            {
                data: wccProbs6,
                N: 10000
            },
            {
                data: wccProbs7,
                N: 10000
            },
            {
                data: wccProbs8,
                N: 10000
            },
            {
                data: wccProbs9,
                N: 10000
            },
            {
                data: wccProbs10,
                N: 10000
            },
            {
                data: wccProbs11,
                N: 10000
            },
            {
                data: wccProbs12,
                N: 10000
            },
            {
                data: wccProbs13,
                N: 10000
            },
            {
                data: wccProbs14,
                N: 10000
            }
        ],
        graph: true,
        timeControl: wccTimeControl,
    },
    "FIDE World Cup 2023": {
        order: 1,
        currentRound: 1,
        type: 'knockout',
        comingSoon: false,
        display: true,
        playerInfo:[
            {},{},{}
        ],
        playerColors: {

        },
        probInfo: [],
        graph: false,
        timeControl: wccTimeControl
    }
};

export default function WCC2024() {

    const [tournamentData, setTournamentData] = useState([]);
    
    const [updater, setUpdater] = useState(false);

    const [updateRate, setUpdateRate] = useState(10 * 1000);

    let isMounted = true;

    useEffect(() => {
        // var t = getTournamentData();
        // return () => {
        //     isMounted = false;
        // }
    }, [])

    // useEffect(() => {
    //     if (updater) {
    //         // console.log("calling update")
    //         const timer = setTimeout(getTournamentData, updateRate);
    //         return () => {
    //             clearTimeout(timer);
    //         }
    //     }
    // }, [tournamentData]);

    // function updaterClock() {
    //     setUpdater(!updater);
    //     setTimeout(updaterClock, updateRate);
    // }


    async function getTournamentData() {
        try {
          const data = await fetch('/tournament_data');
          const json = await data.json();
          const trimmed = [];
          let doUpdate = false;
          for (let key in miscTournamentData) {
            const t = json.find(x => x.name === key);
            if (t !== undefined) {
              if (tournamentData.length > 0) {
                const curRound = t.rounds.sort((a, b) => b.number - a.number)[0];
                const curGame = curRound.games[0];
                const oldRound = tournamentData.find(x => x.name === key).rounds.sort((a, b) => b.number - a.number)[0];
                const oldGame = oldRound.games[0];
                if (curGame.moves.length !== oldGame.moves.length) {
                  doUpdate = true;
                }
              } else {
                doUpdate = true;
              }
              trimmed.push(t);
            }
          }
          setUpdater(true);
          if (doUpdate && isMounted) {
            setTournamentData(trimmed);
          }
          if (isMounted) {
            setTimeout(getTournamentData, updateRate);
          }
        } catch (error) {
          console.error('Error fetching tournament data:', error);
          // Handle error as needed
        }
      }


    const unitStart = 'calc(var(--unit) * ';
    const unitEnd = ')';
    const [showWomens, setShowWomens] = useState(false);
    const border = {
        border: unitStart + .2 + unitEnd + ' solid var(--accent)',
        fontSize: unitStart + 1.5 + unitEnd,
    }
    const noBorder = {
        border: unitStart + .2 + unitEnd + ' solid var(--background)',
        fontSize: unitStart + 1.5 + unitEnd,
    }

    return (
        <div className='wccpage' style={{
            marginTop: unitStart + 3 + unitEnd,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
        }}>
            {/* <div style={{
                width: '75%',
                borderBottom: unitStart + .2 + unitEnd + ' solid var(--accent)',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                alignItems: 'center',
            }}>
                <div className='knockout-tabs' style={{
                    margin: '0'
                }}>
                    <button className='knockout-tab-big'
                        style={!showWomens ? border : noBorder}
                        onClick={() => {setShowWomens(false)}}
                    >
                        {'Open'}
                    </button>
                    <button className='knockout-tab-big'
                        style={showWomens ? border : noBorder}
                        onClick={() => {setShowWomens(true)}}
                    >
                        {'Women'}
                    </button>
                </div>
            </div> */}
            {
                
            }
            {/* {tournamentData.length == 0 ? 
            <h2 style={{
                textAlign: 'center',
                padding: '20%',
                color: 'white',
            }}>Fetching tournament data...</h2>
             : tournamentData.sort((a, b) => { return miscTournamentData[a.name].order - miscTournamentData[b.name].order; }).map((tournament, i) => (
                <TournamentParent 
                key={i + " " + tournament.name}
                tkey={i + " " + tournament.name}
                name={tournament.name}
                currentRound={miscTournamentData[tournament.name].currentRound}
                type={miscTournamentData[tournament.name].type}
                comingSoon={miscTournamentData[tournament.name].comingSoon}
                display={miscTournamentData[tournament.name].display}
                playerColors={miscTournamentData[tournament.name].playerColors}
                probInfo={miscTournamentData[tournament.name].probInfo}
                graph={miscTournamentData[tournament.name].graph}
                db={tournament.rounds.find(x => x.number == miscTournamentData[tournament.name].currentRound)}
                rounds={tournament.rounds}
                timeControl={miscTournamentData[tournament.name].timeControl}
                playerInfo={miscTournamentData[tournament.name].playerInfo}
                N={10000}
                />
            ))} */}
                <h2>World Championship 2023</h2>
                <p style={{
                    
                }}>
                    Chess Assess covered the World Chess Championship 2023 in real time, with live game result probabilities and the following graph, which updated every move.
                </p>
                <img
                    src = {graph}
                    className='graph-image'>
                </img>

            {/*<Tournament 
            name='World Chess Championship 2023'
            subtitle='Game 1'
            type='match'
            comingSoon={true}
            display={true}
            broadcastRoundId='kx9gaE3w'
            playerInfo = {[
                ['Nepo', 2795.0, 0.0, "#938fff"],
                ['Ding', 2788.0, 0.0, "#ff8f8f"],
            ]}
            games = {[
                ['Ding', 'Nepo'],
            ]}
            chances = {[
                [0.21599920898482067, 0.6163473718652897, 0.16759713914788993],
            ]}
            probabilities={wccProbs}
            N={10000}
            depth={depth}
            graph={true}
            />*/}

            {/* <Tournament 
            name='Candidates Tournament 2022'
            subtitle='Finished'
            type='tournament'
            comingSoon={false}
            display={true}
            broadcastRoundId='ZA07lchF'
            playerInfo = {[
                ['Ding', 2806.0, 7.0],
                ['Firouzja', 2793.1, 5.0],
                ['Caruana', 2782.6, 6.5],
                ['Nepo', 2766.4, 9.0],
                ['Rapport', 2764.0, 5.5],
                ['Nakamura', 2760.0, 7.5],
                ['Radjabov', 2748.2, 6.5],
                ['Duda', 2750.0, 5.0],
            ]}
            games = {[
                ['Rapport', 'Radjabov'],
                ['Caruana', 'Firouzja'],
                ['Ding', 'Nakamura'],
                ['Duda', 'Nepo'],
            ]}
            chances = {[
                [0.23466566724939456, 0.6473620483532273, 0.1179722843953783],
                [0.24391905313766132, 0.6488927550214011, 0.10718819183893763],
                [0.2586836675621616, 0.6397994594552858, 0.10151687298055272],
                [0.15867367582238695, 0.6901139190656111, 0.151212405110002],
            ]}
            probabilities={candidatesProbs}
            N={1000}
            depth={depth}
            opacity={30}
            /> */}
        </div>
    )

}