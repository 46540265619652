import React, {useState, useEffect} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  );

  ChartJS.defaults.color = "white";



export const data = {
    labels: ['0','1','2','3','4','5','6','7','8','9','10','11','12'],
    datasets: [
        {
            label: "Nepo",
            data: {
                '0': .5627,
            },
            borderColor: 'rgb(53, 142, 235)',
            backgroundColor: 'rgb(53, 142, 235, .5)',
        },
        {
            label: "Ding",
            data: {
                '0': .4373,
            },
            borderColor: 'rgb(255, 99, 70)',
            backgroundColor: 'rgb(255, 74, 70, .5)',
        },
    ],
}

export default function LineGraph(props) {

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                grid: {
                    display: false
                },
                ticks: {
                    display: true,
                    padding: 0,
                },
                title: {
                    display: false,
                    text: 'minutes since game start',
                    padding: 0,
                },
                // grace: '0%'
                min: 0,
                max: props.data ? Math.max(...props.data.labels) : 1,
            },
            y: {
                grid: {
                    display: false,
                    color: 'white'
                },
                ticks: {
                    display: false
                    // callback: function(val, index) {
                    //     return (val == 0 || val == .5 || val == 1) ? val : '';
                    // },
                },
                min: 0,
                max: 1,
            }
        },
        plugins: {
        legend: {
            display: false,
            // position: 'top',
        },
        title: {
            display: false,
            text: 'Game Result Chances Over Time',
            padding: 5,
            position: 'bottom',
        },
        },
    })

    useEffect(() => {
        var opt = options;
        opt.scales.x.max = props.max;
        setOptions(opt);
    }, [props.max])

    return (
        <div className='game-graph'>
            <Line options={
                
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            type: 'linear',
                            grid: {
                                display: false
                            },
                            ticks: {
                                display: true,
                                padding: 0,
                            },
                            title: {
                                display: false,
                                text: 'minutes since game start',
                                padding: 0,
                            },
                            // grace: '0%'
                            min: 0,
                            max: props.data ? Math.max(...props.data.labels) : 1,
                        },
                        y: {
                            grid: {
                                display: false,
                                color: 'white'
                            },
                            ticks: {
                                display: false
                                // callback: function(val, index) {
                                //     return (val == 0 || val == .5 || val == 1) ? val : '';
                                // },
                            },
                            min: 0,
                            max: 1,
                        }
                    },
                    plugins: {
                    legend: {
                        display: false,
                        // position: 'top',
                    },
                    title: {
                        display: false,
                        text: 'Game Result Chances Over Time',
                        padding: 5,
                        position: 'bottom',
                    },
                    },
                }

            } data={props.data ? props.data : data} plugins={props.plugins} />
        </div>
    )
}