import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Chess } from 'chess.js';
import ReactGA from 'react-ga';
import { toPng } from 'html-to-image'
import html2canvas from 'html2canvas';

import Board from './SimpleBoard';
import StandingsRow from './StandingsRow';
import Tournament from './Tournament';
import TournamentParent from './TournamentParent';
import KnockoutTournament from './KnockoutTournament';
import leftIcon from '../Icons/left.png';
import rightIcon from '../Icons/right.png';

import Rainbow from 'rainbowvis.js';

import LineGraph from './LineGraph';

const depth = 30;

const candidatesTC = [
    {
        moves: 40,
        time: 120 * 60,
        increment: 0,
    },
    {
        moves: Infinity,
        time: 30 * 60,
        increment: 30,
    }
]


const miscTournamentData = {
    "FIDE Candidates 2024": {
        order: 1,
        currentRound: 14,
        type: "round robin",
        comingSoon: false,
        display: true,
        playerInfo: [
            {
                name: "Caruana",
                rating: 2803,
                score: 0.0,
            },
            {
                name: "Nakamura",
                rating: 2789,
                score: 0.0,
            },
            {
                name: "Firouzja",
                rating: 2760,
                score: 0.0,
            },
            {
                name: "Nepomniachtchi",
                rating: 2758,
                score: 0.0,
            },
            {
                name: "Praggnanandhaa",
                rating: 2747,
                score: 0.0,
            },
            {
                name: "Gukesh",
                rating: 2743,
                score: 0.0,
            },
            {
                name: "Vidit",
                rating: 2727,
                score: 0.0,
            },
            {
                name: "Abasov",
                rating: 2632,
                score: 0.0,
            },
        ],
        playerColors: {
            "Caruana": "#21f0b6",
            "Nakamura": "#ec102f",
            "Firouzja": "#77d1fd",
            "Nepomniachtchi": "#2aa63a",
            "Praggnanandhaa": "#fc67ba",
            "Gukesh": "#ffb4a2",
            "Vidit": "#eac328",
            "Abasov": "#4e66b6",
        },
        schedule: [
            [
                ['Caruana', 'Nakamura'],
                ['Abasov', 'Nepomniachtchi'],
                ['Firouzja', 'Praggnanandhaa'],
                ['Gukesh', 'Vidit'],
            ],
            [
                ['Nakamura', 'Vidit'],
                ['Praggnanandhaa', 'Gukesh'],
                ['Nepomniachtchi', 'Firouzja'],
                ['Caruana', 'Abasov'],
            ],
            [
                ["Abasov", "Nakamura"],
                ["Firouzja", "Caruana"],
                ["Gukesh", "Nepomniachtchi"],
                ["Vidit", "Praggnanandhaa"],
            ],
            [
                ["Nakamura", "Praggnanandhaa"],
                ["Nepomniachtchi", "Vidit"],
                ["Caruana", "Gukesh"],
                ["Abasov", "Firouzja"],
            ],
            [
                ["Firouzja", "Nakamura"],
                ["Gukesh", "Abasov"],
                ["Vidit", "Caruana"],
                ["Praggnanandhaa", "Nepomniachtchi"],
            ],
            [
                ["Gukesh", "Nakamura"],
                ["Vidit", "Firouzja"],
                ["Praggnanandhaa", "Abasov"],
                ["Nepomniachtchi", "Caruana"],
            ],
            [
                ["Nakamura", "Nepomniachtchi"],
                ["Caruana", "Praggnanandhaa"],
                ["Abasov", "Vidit"],
                ["Firouzja", "Gukesh"],
            ],
            [
                ["Nakamura", "Caruana"],
                ["Nepomniachtchi", "Abasov"],
                ["Praggnanandhaa", "Firouzja"],
                ["Vidit", "Gukesh"],
            ],
            [
                ["Vidit", "Nakamura"],
                ["Gukesh", "Praggnanandhaa"],
                ["Firouzja", "Nepomniachtchi"],
                ["Abasov", "Caruana"],
            ],
            [
                ["Nakamura", "Abasov"],
                ["Caruana", "Firouzja"],
                ["Nepomniachtchi", "Gukesh"],
                ["Praggnanandhaa", "Vidit"],
            ],
            [
                ["Praggnanandhaa", "Nakamura"],
                ["Vidit", "Nepomniachtchi"],
                ["Gukesh", "Caruana"],
                ["Firouzja", "Abasov"],
            ],
            [
                ["Nakamura", "Firouzja"],
                ["Abasov", "Gukesh"],
                ["Caruana", "Vidit"],
                ["Nepomniachtchi", "Praggnanandhaa"],
            ],
            [
                ["Nepomniachtchi", "Nakamura"],
                ["Praggnanandhaa", "Caruana"],
                ["Vidit", "Abasov"],
                ["Gukesh", "Firouzja"],
            ],
            [
                ["Nakamura", "Gukesh"],
                ["Firouzja", "Vidit"],
                ["Abasov", "Praggnanandhaa"],
                ["Caruana", "Nepomniachtchi"],
            ],
        ]
    }
};

export default function Candidates2024() {

    const [tournamentData, setTournamentData] = useState([]);
    
    const [updater, setUpdater] = useState(false);

    const [updateRate, setUpdateRate] = useState(10 * 1000);

    const [probJSON, setProbJSON] = useState({});

    const [roundEnds, setRoundEnds] = useState([]);

    const [standingsData, setStandingsData] = useState([]);

    const [criticalMoves, setCriticalMoves] = useState([]);

    const [boardData, setBoardData] = useState([]);

    const [selectedRound, setSelectedRound] = useState(miscTournamentData["FIDE Candidates 2024"].currentRound)

    const [zoomBounds, setZoomBounds] = useState([])

    const [gameGraphData, setGameGraphData] = useState([
        {
            labels: [0],
            datasets: []
        },
        {
            labels: [0],
            datasets: []
        },
        {
            labels: [0],
            datasets: []
        },
        {
            labels: [0],
            datasets: []
        },
    ]);

    const [showAllRounds, setShowAllRounds] = useState(false);
    const [critFromAll, setCritFromAll] = useState(false);

    let isMounted = true;

    const gyrRainbow = new Rainbow()
    gyrRainbow.setSpectrum('FF4444', 'FFFF44', '#44FF44')
    gyrRainbow.setNumberRange(-7.5, 7.5)

    const bRainbow = new Rainbow()
    bRainbow.setSpectrum('#3bfff8', '#ff2a00')
    bRainbow.setNumberRange(1, 1800)

    const standingsRainbow = new Rainbow()
    standingsRainbow.setSpectrum('FF4444', 'FFFF44', '#44FF44')
    standingsRainbow.setNumberRange(0, .5)

    useEffect(() => {
        // console.log("Begin")
        const fetchData = async () => {
            try {
                // console.log("getting /api/json")
                const response = await axios.get('/api/json');
                if (!isMounted) return
                // console.log("setting json...")
                setProbJSON(response.data);
                // console.log("setting json data...")
                // console.log(response.data)
            } catch (error) {
                console.error('Error fetching the JSON files:', error);
            }
        };

        fetchData()

        return () => {
            isMounted = false;
        }
    }, []);

    useEffect(() => {
        // console.log("json changed", Object.values(probJSON).length)
        if (Object.values(probJSON).length > 0) getTournamentData()

        return () => {
            isMounted = false;
        }
    }, [probJSON])

    async function getTournamentData() {
        try {
            // console.log("setting tournament data...")
            const data = await fetch('/tournament_data');
            const json = await data.json();
            // console.log("done waiting...")
            const trimmed = [];
            let doUpdate = false;
            for (let key in miscTournamentData) {
                const t = json.find(x => x.name === key);
                if (t !== undefined) {
                    if (tournamentData.length > 0) {
                        const curRound = t.rounds.sort((a, b) => b.number - a.number)[0];
                        const curGame = curRound.games[0];
                        const oldRound = tournamentData.find(x => x.name === key).rounds.sort((a, b) => b.number - a.number)[0];
                        const oldGame = oldRound.games[0];
                        if (curGame.moves.length !== oldGame.moves.length) {
                            doUpdate = true;
                        }
                    } else {
                        doUpdate = true;
                    }
                    trimmed.push(t);
                }
            }
            setUpdater(true);
            if (doUpdate && isMounted) {
                setTournamentData(trimmed);
            }
            if (isMounted) {
                setTimeout(getTournamentData, updateRate);
            }
        } catch (error) {
            console.error('Error fetching tournament data:', error);
            // Handle error as needed
        }
        // console.log("done")
    }

    useEffect(() => {
        if (tournamentData[0] !== undefined) setGraphData(getGraphData())
    }, [tournamentData, selectedRound, showAllRounds, critFromAll])

    const [graphData, setGraphData] = useState({
        labels: [],
        datasets: []
    })

    function getTime(ply, wSeconds, bSeconds) {
        var wMoves = Math.ceil(ply / 2.0)
        var bMoves = Math.floor(ply / 2.0)
        var wTotal = 120 * 60 + 30 * 60 + 30 * Math.max(0, (wMoves - 40));
        // if (wMoves > 40) {
        //     wTotal += 30 * 60 + 30 * (wMoves - 40)
        // }
        var bTotal = 120 * 60 + 30 * 60 + 30 * Math.max(0, (bMoves - 40));
        // if (bMoves > 40) {
        //     bTotal += 30 * 60 + 30 * (bMoves - 40)
        // }
        return Math.max(wTotal + bTotal - wSeconds - bSeconds, 0)
    }

    function timeStringToSeconds(timeString) {
        if (timeString == null) return 1
        const timeArray = timeString.split(':').map(Number);
        
        if (timeArray.length !== 3) {
        throw new Error('Invalid time format. Expected format: "1:59:30"');
        }
        
        const [hours, minutes, seconds] = timeArray;
        
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        throw new Error('Invalid time format. Expected format: "1:59:30"');
        }
        
        if (hours < 0 || minutes < 0 || seconds < 0) {
        throw new Error('Time values cannot be negative.');
        }
        
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        return totalSeconds;
    }

    const results = [ "1-0", "1/2-1/2", "0-1" ]
    function generateAllCombos(gameCount, combo, allCombos) {
        if (gameCount === 0) {
            allCombos.push(combo.slice()); // Push a copy of the current combo
        } else {
            for (let result of results) {
                combo.push(result);
                generateAllCombos(gameCount - 1, combo, allCombos);
                combo.pop();
            }
        }
    }

    const orderedNames = [
        "Caruana",
        "Nakamura",
        "Firouzja",
        "Nepomniachtchi",
        "Praggnanandhaa",
        "Gukesh",
        "Vidit",
        "Abasov",
    ]
    function getPlayerProbs(roundIndex, gamesProbs) {
        const resultMap = {
            "1-0": 0,
            "1/2-1/2": 1,
            "0-1": 2
        }
        const allCombos = [];
        generateAllCombos(4, [], allCombos);

        var probs = {}
        orderedNames.forEach((name, i) => {
            probs[name] = 0
        })
        allCombos.forEach((c, i) => {
            var pCombo = 1
            var cStr = c.join("\t");
            // console.log(cStr)
            c.forEach((result, j) => {
                const wName = miscTournamentData["FIDE Candidates 2024"].schedule[roundIndex][j][0];
                const bName = miscTournamentData["FIDE Candidates 2024"].schedule[roundIndex][j][1];
                const index = tournamentData[0].rounds[roundIndex].games.findIndex(item => item.whitePlayer.name == wName && item.blackPlayer.name == bName);
                var prob = gamesProbs[index][resultMap[result]]
                if (isNaN(prob)) prob = 0
                pCombo *= prob
            })
            orderedNames.forEach((name, j) => {
                probs[name] += pCombo * probJSON[`round${roundIndex + 1}`][cStr][j]
            })
        })
        
        return probs
    }

    function getGraphData() {
        var standings = {}
        var datasets = {}
        miscTournamentData["FIDE Candidates 2024"].playerInfo.forEach((player, i) => {
            datasets[player.name] = {
                label: player.name,
                data: [],
                // pointStyle: function(context) {
                //     const index = context.dataIndex;
                //     if (index === context.dataset.data.length - 1) {
                //         return 'circle'; // Show circle for the final point
                //     } else {
                //         return 'none'; // Hide points for other data points
                //     }
                // },
                pointBackgroundColor: [],
                pointBorderColor: [],
                pointStyle: 'circle',
                pointRadius: 5,
                tension: .03,
                fill: false,
                borderWidth: 2,
                borderColor: miscTournamentData["FIDE Candidates 2024"].playerColors[player.name],
                backgroundColor: miscTournamentData["FIDE Candidates 2024"].playerColors[player.name] + '88',
            }
            standings[player.name] = {
                name: player.name,
                color: miscTournamentData["FIDE Candidates 2024"].playerColors[player.name],
                score: miscTournamentData["FIDE Candidates 2024"].playerInfo.find(x => x.name == player.name).score,
                prob: 0,
            }
        })

        var boards = []
        var moveData = []
        var roundTimes = []
        var initialGamesProbs = []
        var gamesGraphs = []
        tournamentData[0].rounds.forEach((r, i) => {
            // if (i + 1 > selectedRound) return;
            const lastRoundTime = i > 0 ? roundTimes[i - 1] : 0
            roundTimes.push(lastRoundTime)
            initialGamesProbs.push([])
            r.games.forEach((g, j) => {
                const probs = [g.wProb, g.dProb, g.bProb]
                var gameLabels = [0]
                var gamedsets = {
                    "white": {
                        fill: true,
                        label: "White Win",
                        data: [probs[0]],
                        borderColor: 'rgb(240, 240, 240)',
                        backgroundColor: 'rgb(240, 240, 240, 1)',
                        pointStyle: false,
                        // tension: .2,
                    },
                    "draw": {
                        fill: true,
                        label: "Draw",
                        data: [probs[0] + probs[1]],
                        borderColor: 'rgb(140, 140, 140)',
                        backgroundColor: 'rgb(140, 140, 140, 1)',
                        pointStyle: false,
                        // tension: .2,
                    },
                    "black": {
                        fill: true,
                        label: "Black Win",
                        data: [probs[0] + probs[1] + probs[2]],
                        borderColor: 'rgb(0, 0, 0)',
                        backgroundColor: 'rgb(0, 0, 0, 1)',
                        pointStyle: false,
                        // tension: .2,
                    }
                }
                moveData.push({
                    time: lastRoundTime,
                    roundIndex: i,
                    gameIndex: j,
                    probs: probs,
                    move: null
                })
                initialGamesProbs[i].push(probs)
                var lastTime = lastRoundTime
                var lastEval = 0;
                var lastProbs = [0,0,0]
                var lastWSecs = 1;
                var lastBSecs = 1;
                g.moves.forEach((m, k) => {
                    var res = ""
                    if (k == g.moves.length - 1)
                    {
                        if (g.pgn.includes("1-0")) res = "1-0"
                        if (g.pgn.includes("1/2-1/2")) res = "1/2-1/2"
                        if (g.pgn.includes("0-1")) res = "0-1"
                    }
                    // if (i == tournamentData[0].rounds.length - 1) {
                    if (i + 1 <= selectedRound) {
                        if (res == "1-0") {
                            standings[g.whitePlayer.name].score += 1
                        } else if (res == "1/2-1/2") {
                            standings[g.whitePlayer.name].score += .5
                            standings[g.blackPlayer.name].score += .5
                        } else if (res == "0-1") {
                            standings[g.blackPlayer.name].score += 1
                        }
                    }
                    // }
                    var wSeconds = timeStringToSeconds(m.wClock)
                    var bSeconds = timeStringToSeconds(m.bClock)
                    if (m.ply < 79) {
                        wSeconds += 30 * 60
                        bSeconds += 30 * 60
                    }
                    if (m.ply == 79) bSeconds += 30 * 60
                    lastWSecs = wSeconds
                    lastBSecs = bSeconds
                    const time = getTime(m.ply, wSeconds, bSeconds)
                    if (!m.eval != null && !isNaN(m.eval))
                    {
                        var dur = time + lastRoundTime - lastTime
                        dur = (dur < 0 ? time + lastRoundTime + 30 * 60 - lastTime : dur)
                        if (dur < 0) dur = 1
                        const probs = res == "1-0" ? [1,0,0] : res == "1/2-1/2" ? [0,1,0] : res == "0-1" ? [0,0,1] : [m.wProb, m.dProb, m.bProb];
                        gameLabels.push((k + 1) / 2)
                        gamedsets["white"].data.push(probs[0])
                        gamedsets["draw"].data.push(probs[0] + probs[1])
                        gamedsets["black"].data.push(probs[0] + probs[1] + probs[2])
                        lastProbs = probs
                        const moveText = Math.floor((m.ply - 1) / 2 + 1) + (m.ply % 2 == 1 ? "." : "...") + m.san + " " + res
                        moveData.push({
                            time: time + lastRoundTime,
                            roundIndex: i,
                            gameIndex: j,
                            probs: probs,
                            move: moveText,
                            player: m.ply % 2 == 1 ? g.whitePlayer.name : g.blackPlayer.name,
                            opponent: m.ply % 2 == 0 ? g.whitePlayer.name : g.blackPlayer.name,
                            duration: dur,
                            res: res,
                            white: (m.ply % 2 == 1),
                            ply: m.ply,
                        })
                        roundTimes[i] = Math.max(lastRoundTime + time, roundTimes[i])
                        lastTime = lastRoundTime + time
                        lastEval = m.eval
                    }
                })
                if (i + 1 == selectedRound) {
                    gameLabels.sort((a, b) => a - b)
                    gamesGraphs.push({
                        labels: gameLabels,
                        datasets: Object.values(gamedsets),
                    })
                    boards.push({
                        whiteName: g.whitePlayer.name,
                        blackName: g.blackPlayer.name,
                        eval: lastEval,
                        whiteClock: lastWSecs,
                        blackClock: lastBSecs,
                        probs: lastProbs,
                        dbgame: g,
                        pgn: g.pgn,
                    })
                }
            })
        })
        setBoardData(boards);
        setGameGraphData(gamesGraphs);

        // moveData.push({
        //     time: 10,
        //     roundIndex: 0,
        //     gameIndex: 1,
        //     probs: [0,0,1]
        // })

        moveData.sort((a, b) => {
            return a.time - b.time
        })

        var critMoves = []
        var prevProbs = {}
        orderedNames.forEach((name, i) => {
            prevProbs[name] = 0
        })

        var labels = []
        var currentProbs = [[],[],[],[]]
        var currentRound = 1
        initialGamesProbs[currentRound - 1].forEach((p, i) => {
            currentProbs[i] = p
        })
        var zoom = [0, 0, -1, 0]
        var totalBounds = [-1, 0]
        moveData.forEach((data, i) => {
            const sameAsNext = (i < moveData.length - 1 && moveData[i + 1].time == data.time)
            if (!sameAsNext) {
                const h = data.time / 60 / 60;
                if (data.roundIndex + 1 == selectedRound) {
                    if (zoom[0] == 0) zoom[0] = h;
                    else zoom[1] = h;
                }
                if (totalBounds[0] == -1) totalBounds[0] = h;
                else totalBounds[1] = h;
            }
        })

        var ends = [0]

        // setRoundEnds([0, ...(roundTimes.map(x => x / 60 / 60))])

        // console.log(zoom)
        // console.log(totalBounds)
        var rd = 0
        moveData.forEach((data, i) => {
            // if (!showAllRounds && data.roundIndex + 1 < selectedRound) return;
            const sameAsNext = (i < moveData.length - 1 && moveData[i + 1].time == data.time)
            if (!sameAsNext) {
                const h = data.time / 60 / 60;
                var adjH = h
                if (showAllRounds || h < zoom[0]) {
                    labels.push(h);
                } else if (h < zoom[1]) {
                    const len = ((totalBounds[1] - totalBounds[0]) - (zoom[1] - zoom[0]))
                    const adjH = zoom[0] + len * ((h - zoom[0]) / (zoom[1] - zoom[0]))
                    labels.push(adjH);
                    if (zoom[2] == -1) zoom[2] = adjH
                    zoom[3] = adjH;
                } else {
                    adjH = h - zoom[1] + zoom[3]
                    labels.push(adjH)
                }
                if (rd != data.roundIndex + 1) {
                    rd = data.roundIndex + 1
                    ends.push(adjH);
                } else {
                    ends[rd] = adjH
                }
            }
            if (data.roundIndex + 1 > currentRound) {
                currentRound = data.roundIndex + 1
                initialGamesProbs[currentRound - 1].forEach((p, i) => {
                    currentProbs[i] = p
                })
            }
            currentProbs[data.gameIndex] = data.probs
            const playerProbs = getPlayerProbs(currentRound - 1, currentProbs)
            if (!sameAsNext) {
                for (var key in playerProbs) {
                    datasets[key].data.push(playerProbs[key])
                    if (data.roundIndex + 1 <= selectedRound) standings[key].prob = playerProbs[key]
                    
                    if (
                        // data.roundIndex == selectedRound - 1
                        ((!critFromAll && data.roundIndex == selectedRound - 1)
                            || (critFromAll && data.roundIndex <= selectedRound))
                        && data.move != null && data.res == "" 
                        && (key == data.player || key == data.opponent)
                        && data.ply > 10
                        ) {
                        const index = critMoves.findIndex(item => item.move == data.move && item.player == data.player && item.opponent == data.opponent)
                        if (index !== -1)
                        {
                            critMoves[index].effects[key] = playerProbs[key] - prevProbs[key]
                            critMoves[index].fromsAndTos[key] = [prevProbs[key], playerProbs[key]]
                        }
                        else
                        {
                            var effects = {}
                            effects[key] = playerProbs[key] - prevProbs[key]
                            var fromsAndTos = {}
                            fromsAndTos[key] = [prevProbs[key], playerProbs[key]]
                            critMoves.push({
                                player: data.player,
                                opponent: data.opponent,
                                move: data.move,
                                affectedPlayer: key,
                                effect: playerProbs[key] - prevProbs[key],
                                effects: effects,
                                fromsAndTos: fromsAndTos,
                                duration: data.duration,
                                white: data.white,
                                time: data.time,
                                round: data.roundIndex + 1,
                            })
                        }
                    }
                }
            }
            prevProbs = playerProbs
        })
        setRoundEnds(ends);
        setZoomBounds(zoom);
        // console.log(zoom)
        var sortedDatasets = Object.values(datasets)
        sortedDatasets.sort((a, b) => b.data[b.data.length - 1] - a.data[a.data.length - 1])

        sortedDatasets.forEach((set, i) => {
            set.data.forEach((dataPoint, j) => {
                if (j == 0) {
                    set.pointBackgroundColor.push(set.backgroundColor)
                    set.pointBorderColor.push(set.borderColor)
                } else if (j == set.data.length - 1) {
                    set.pointBackgroundColor.push(set.borderColor)
                    set.pointBorderColor.push(set.borderColor)
                } else {
                    set.pointBackgroundColor.push('#0000')
                    set.pointBorderColor.push('#0000')
                }
            })
        })

        const data = {
            labels: labels,
            datasets: sortedDatasets,
        }
        // console.log("data:")
        // console.log(data);

        var sortedStandings = Object.values(standings)
        sortedStandings.sort((a, b) => { return b.prob - a.prob })
        standingsRainbow.setNumberRange(sortedStandings[sortedStandings.length - 1].prob, Math.max(.01, sortedStandings[0].prob))
        sortedStandings.sort((a, b) => { return b.score + b.prob / 1000 - a.score - a.prob / 1000 })
        setStandingsData(sortedStandings)

        critMoves.sort((a, b) => Math.max(...Object.values(b.effects).map(Math.abs)) - Math.max(...Object.values(a.effects).map(Math.abs)))
        critMoves = critMoves.slice(0, 10);
        setCriticalMoves(critMoves);
        // console.log(critMoves)

        return data
    }



    const unitStart = 'calc(var(--unit) * ';
    const unitEnd = ')';
    const [showMovesOnGraph, setShowMovesOnGraph] = useState(false);
    const border = {
        border: unitStart + .3 + unitEnd + ' solid var(--accent)',
        // fontSize: unitStart + 1.5 + unitEnd,
        color: 'var(--text-hover)'
    }
    const noBorder = {
        border: unitStart + .3 + unitEnd + ' solid var(--background)',
        // fontSize: unitStart + 1.5 + unitEnd,
    }
    

    return (
        <div className={'no-transition'} style={{
            marginTop: unitStart + 3 + unitEnd,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
        }}>
            
            <h2 style={{color: 'var(--text)'}}
            >FIDE Candidates Tournament 2024</h2>
            <div className='tournament-graph-container-top'>
                {/* {tournamentData == null || tournamentData.length == 0 ? (
                    <h3 style={{color: 'var(--text)', textAlign: 'center'}}>Loading data...</h3>
                ) : (<h3></h3>)} */}
                <LineGraph data-export="true"
                    key={showMovesOnGraph + ' ' + (roundEnds.join()) + ' ' + graphData?.labels.length}
                    data={graphData}
                    zoom={!showAllRounds ? zoomBounds : null}
                    plugins={[
                        {
                            id: 'arbitraryLine',
                            beforeDatasetsDraw(chart, args, pluginOptions) {
                                const {ctx, chartArea: {top,bottom,left,right,width,height}, scales: {x,y}} = chart;
                                ctx.save();
                                
                                // // console.log("hi")
                                // // console.log(roundEnds);
                                roundEnds.forEach((v, i) => {
                                    // // console.log("v")
                                    // // console.log(v)
                                    ctx.beginPath();
                                    ctx.lineWidth = 1;
                                    ctx.strokeStyle = '#fffa';
                                    ctx.moveTo(x.getPixelForValue(v), top);
                                    ctx.lineTo(x.getPixelForValue(v), bottom);
                                    ctx.stroke();

                                });
                                // console.log(roundEnds)
                                for (var i = 1; i < roundEnds.length; i++) {
                                    const xLoc = roundEnds[i-1] / 2 + roundEnds[i] / 2;
                                    ctx.beginPath();
                                    ctx.fillStyle = 'black';
                                    const xcord = x.getPixelForValue(xLoc);
                                    const ycord = top * .925 + bottom * .075;
                                    const rad = 15;
                                    // ctx.arc(x.getPixelForValue(xLoc), top * .85 + bottom * .15, rad, 0, 2 * Math.PI);
                                    const w = rad + rad / 4 + rad / 2 * Math.trunc(i / 10);
                                    const h = rad;
                                    ctx.roundRect(xcord - w/2, ycord - h/2, w, h, rad / 4);
                                    ctx.fill();
                                    ctx.fillStyle = "#ffffff";
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = "middle";
                                    ctx.fillText('R' + (i), xcord, ycord);
                                }
                            },
                            afterDatasetsDraw(chart, args, pluginOptions) {
                                const {ctx, chartArea: {top,bottom,left,right,width,height}, scales: {x,y}} = chart;
                                ctx.save();

                                if (showMovesOnGraph) {
                                    criticalMoves.forEach((move, i) => {
                                        const col = makeWhiter(miscTournamentData["FIDE Candidates 2024"].playerColors[move.player]) + "aa"
    
                                        ctx.beginPath();
                                        ctx.lineWidth = 1.5;
                                        ctx.strokeStyle = col;
                                        const hours = (move.time) / 60 / 60
                                        ctx.moveTo(x.getPixelForValue(hours), top);
                                        ctx.lineTo(x.getPixelForValue(hours), bottom);
                                        ctx.stroke();
                                    })
    
                                    criticalMoves.forEach((move, i) => {
                                        const col = makeWhiter(miscTournamentData["FIDE Candidates 2024"].playerColors[move.player]) + "aa"
                                        const hours = (move.time) / 60 / 60
    
                                        ctx.beginPath();
                                        ctx.fillStyle = 'black';
                                        const xcord = x.getPixelForValue(hours);
                                        const b = .125 + i * .055
                                        const ycord = top * (1 - b) + bottom * b;
                                        const rad = 20;
                                        const w = 1.75 * rad + 3 * (move.move.length - 4);
                                        const h = rad;
                                        ctx.roundRect(xcord - w/2, ycord - h/2, w, h, rad / 4);
                                        ctx.fill();
                                        ctx.fillStyle = col;
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = "middle";
                                        ctx.fillText(move.move, xcord, ycord);
                                    })
                                }
                            }
                        }
                    ]}
                    /> 

                <div className='toggle-holder'>
                    <button onClick={() => setShowAllRounds(false)} className={`toggle-button bottom-button ` + (!showAllRounds ? 'toggle-on3' : 'toggle-off3')}>
                        <h3>Expanded Round</h3>
                    </button>
                    <button onClick={() => setShowAllRounds(true)} className={`toggle-button bottom-button ` + (showAllRounds ? 'toggle-on3' : 'toggle-off3')}>
                        <h3>All Rounds</h3>
                    </button>
                </div>

                <div className='board-stuff-and-graph'>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <button className='smaller small-button'
                            onClick={() => setSelectedRound(Math.max(1, selectedRound - 1))}>
                            <img className='arrow-icon button-icon' src={leftIcon} alt='<' />
                        </button>
                        {/* <button className='in-line-button'
                            onClick={() => setShowAllRounds(!showAllRounds)}> */}
                            <h2>
                                {"Round " + selectedRound}
                            </h2>
                        {/* </button> */}
                        <button className='smaller'
                            onClick={() => setSelectedRound(Math.min(selectedRound + 1, miscTournamentData["FIDE Candidates 2024"].currentRound))}>
                            <img className='arrow-icon button-icon' src={rightIcon} alt='>' />
                        </button>
                    </div>
                    <div className='board-grid'>
                        {boardData.map((board, i) => (
                            <div className="board-in-grid" key={selectedRound + " " + i}>
                                <h3>{board.whiteName + " - " + board.blackName}</h3>
                                <Board 
                                    type={'small-tournament'}
                                    pieceSize={16}
                                    selectedResult='x'
                                    whitePlayer={board.whiteName} blackPlayer={board.blackName}
                                    wcol={miscTournamentData["FIDE Candidates 2024"].playerColors[board.whiteName]}
                                    bcol={miscTournamentData["FIDE Candidates 2024"].playerColors[board.blackName]}
                                    eval={board.eval}
                                    whiteClock={board.whiteClock} blackClock={board.blackClock}
                                    probs={board.probs}
                                    dbgame={board.dbgame}
                                    pgn={board.pgn}
                                    graphData={gameGraphData[i]}
                                />
                            </div>
                        ))}
                    </div>

                    <div className='standings-and-moves'>
                        <div className='standings-container'
                                // style={{...standingsHeight}}
                                >
                            <h3 style={{backgroundColor: 'var(--dark-background)', color: 'var(--text-hover)'}}>
                                {'Standings Through Rd ' + selectedRound}
                            </h3>
                            <table className='standings-table' data-export="true">
                                <tbody>
                                <tr>
                                    <th>Player</th>
                                    <th>Score</th>
                                    <th>Win%
                                        {/* {winP ? 'Win%' : 'Top2%'}
                                        <span className='tooltiptext'>{'change to ' + (winP ? 'Top2%' : 'Win%')}</span> */}
                                    </th>
                                    {/* <th>{winP ? 'Win%' : 'Top2%'}</th> */}
                                </tr>
                                {standingsData.map((row) => {
                                    standingsRainbow.setNumberRange(standingsData[standingsData.length - 1].prob, Math.max(.01, standingsData[0].prob))
                                    return (
                                    <StandingsRow
                                        key={row.name}
                                        name={row.name}
                                        score={row.score}
                                        winChance={row.prob * 100}
                                        original={standingsData}
                                        maxGap={0}
                                        doColor={false}
                                        pcol={makeWhiter(row.color)}
                                        winColor={standingsRainbow.colorAt(row.prob)}
                                    />
                                )})}
                                </tbody>
                            </table>
                        </div>
                        <div className='moves-with-buttons'>
                            {showAllRounds ? 
                            <div className='toggle-holder'>
                                <button onClick={() => setCritFromAll(false)} className={`toggle-button top-button ` + (!critFromAll ? 'toggle-on2' : 'toggle-off2')}>
                                    <h3>This Round</h3>
                                </button>
                                <button onClick={() => setCritFromAll(true)} className={`toggle-button top-button ` + (critFromAll ? 'toggle-on2' : 'toggle-off2')}>
                                    <h3>All Rounds</h3>
                                </button>
                            </div>
                            : <></>}
                            <div className='moves-container'>
                                <div style={{backgroundColor: 'var(--dark-background)', display: 'flex', justifyContent: 'space-evenly'}}>
                                    <h3 style={{ color: 'var(--text-hover)'}}>
                                        {critFromAll ? "Most Critical Moves Through Rd " + selectedRound
                                        : 'Round ' + selectedRound + '\'s Most Critical Moves'}
                                    </h3>
                                    {/* <button style={showMovesOnGraph ? {...border} : {...noBorder}} className='in-line-button'
                                        onClick={() => setShowMovesOnGraph(!showMovesOnGraph)}>
                                        <h4>Show on Graph</h4>
                                    </button> */}
                                </div>
                                <table className='standings-table' data-export="true">
                                    <tbody>
                                        <tr>
                                            <th>#</th>
                                            {critFromAll ? <th>Rd</th> : <></>}
                                            <th>Move</th>
                                            <th>Player</th>
                                            <th style={{whiteSpace: 'nowrap'}}>Time Used</th>
                                            <th>Effect</th>
                                        </tr>
                                        {criticalMoves.map((move, i) => (
                                            <tr key={move.move + " " + move.effect}>
                                                <td><b style={{color: (move.white ? "#FFF" : "#999")}}>{i + 1}</b></td>
                                                {critFromAll ? <td><b style={{color: (move.white ? "#FFF" : "#999")}}>{move.round}</b></td> : <></>}
                                                <td><b style={{color: (move.white ? "#FFF" : "#999")}}>{move.move}</b></td>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <b style={{color: makeWhiter(miscTournamentData["FIDE Candidates 2024"].playerColors[move.player])}}>
                                                        {move.player}
                                                    </b>
                                                    {" vs "}
                                                    <b style={{color: makeWhiter(miscTournamentData["FIDE Candidates 2024"].playerColors[move.opponent])}}>
                                                        {move.opponent}
                                                    </b>
                                                </td>
                                                <td><b style={{color: '#' + bRainbow.colorAt(move.duration)}}>{formatTime(move.duration)}</b></td>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    {Object.keys(move.effects).map((name, i) => (
                                                        <div key={i}>
                                                            <b style={{color: makeWhiter(miscTournamentData["FIDE Candidates 2024"].playerColors[name])}}>
                                                                {`${name}:\t`}
                                                            </b>
                                                            {/* <b style={{color: '#' + gyrRainbow.colorAt(move.effects[name] * 100)}}>
                                                                {(move.effects[name] >= 0 ? '+' : '') + (Math.round(move.effects[name] * 10000) / 100) + "%"}
                                                            </b> */}
                                                            <b style={{color: '#' + gyrRainbow.colorAt(move.effects[name] * 100)}}>
                                                                {(Math.round(move.fromsAndTos[name][0] * 1000) / 10) + "% -> " + (Math.round(move.fromsAndTos[name][1] * 1000) / 10) + "%"}
                                                            </b>
                                                        </div>
                                                    ))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>




                
                
            </div>

        </div>
    )


    function formatTime(seconds) {
        // Calculate minutes and seconds
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;
      
        // Add leading zero if necessary
        let formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        let formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
      
        // Combine minutes and seconds with a colon
        let formattedTime = `${formattedMinutes}:${formattedSeconds}`;
      
        return formattedTime;
      }


      function makeWhiter(hexColor) {
        // Remove the '#' if it's included
        hexColor = hexColor.replace('#', '');
      
        // Convert the hex color to RGB components
        let r = parseInt(hexColor.substring(0, 2), 16);
        let g = parseInt(hexColor.substring(2, 4), 16);
        let b = parseInt(hexColor.substring(4, 6), 16);
      
        // Define white color
        let whiteR = 255;
        let whiteG = 255;
        let whiteB = 255;
      
        // Calculate the average color
        let avgR = Math.round((r + whiteR) / 2);
        let avgG = Math.round((g + whiteG) / 2);
        let avgB = Math.round((b + whiteB) / 2);
      
        // Convert the average RGB values back to hexadecimal
        let avgHex = "#" + componentToHex(avgR) + componentToHex(avgG) + componentToHex(avgB);
      
        return avgHex;
      }

      // Helper function to convert RGB to hex component
      function componentToHex(c) {
        let hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }
    }