import React, {useState, useEffect, useRef} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  );


  

export const data = {
    labels: ['0','1','2','3','4','5','6','7','8','9','10','11','12'],
    datasets: [
        // {
        //     label: "Nepo",
        //     data: {
        //         '0': .5627,
        //     },
        //     borderColor: 'rgb(53, 142, 235)',
        //     backgroundColor: 'rgb(53, 142, 235, .5)',
        // },
        // {
        //     label: "Ding",
        //     data: {
        //         '0': .4373,
        //     },
        //     borderColor: 'rgb(255, 99, 70)',
        //     backgroundColor: 'rgb(255, 74, 70, .5)',
        // },
    ],
}

export default function LineGraph(props) {


    const options = {
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 1.5,
        scales: {
            x: {
                type: 'linear',
                grid: {
                    display: true,
                    drawOnChartArea: false,
                    color: '#ffffff44'
                },
                ticks: {
                    stepSize: 1,
                    display: true,
                    callback: (value) => {
                        var val = Math.round(2 * value) / 2;
                        if (props.zoom != null) {
                            if (value < props.zoom[2]) {
                                val = Math.round(2 * value) / 2;
                            } else if (value < props.zoom[3]) {
                                var mapped = props.zoom[0] + (props.zoom[1] - props.zoom[0]) * ((value - props.zoom[2]) / (props.zoom[3] - props.zoom[2]))
                                val = mapped; 
                                let hours = Math.floor(val);
                                // Multiply the fractional part by 60 to get minutes
                                let minutes = Math.round((val - hours) * 60);
                                // Ensure minutes are two digits
                                if (minutes === 60) {
                                    minutes = 0;
                                    hours += 1; // Increment the hour if minutes roll over
                                }
                                // Format the time in "HH:MM"
                                val = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                            } else {
                                val = value - (props.zoom[3] - props.zoom[2]) + (props.zoom[1] - props.zoom[0]);
                                val = Math.round(2 * val) / 2
                            }
                        }
                        return val;
                    }
                },
                title: {
                    display: true,
                    text: 'hours of game time',
                    padding: 0,
                },
                afterDataLimits(scale) {
                  scale.max += .5; // Add padding to the right side of the chart
                },
                min: props.data ? Math.max(Math.min(...props.data.labels), 0) : 0,
                max: props.data ? Math.max(...props.data.labels) : 1,
            },
            y: {
                min: 0,
                max: Math.min(
                    Math.ceil(Math.max(...props.data.datasets.flatMap(dataset => dataset.data)) * 10) / 10 + .1
                    , 1),
                grid: {
                    display: true,
                    color: '#ffffff44'
                },
                ticks: {
                    stepSize: 0.1,
                    display: true,
                    callback: function(val, index) {
                        if (val == 1) return '100%'
                        if (val == 0) return '0%'
                        return (val * 100).toPrecision(2) + '%'
                    },
                },
            }
        },
        plugins: {
          legend: {
            position: 'right',
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                fontSize: 50,
                callback: function(val, index) {
                    if (val == "Praggnanandhaa") return "Pragg"
                    if (val == "Nepomniachtchi") return "Nepo"
                    return val;
                },
            }
          },
          title: {
              display: true,
              text: 'Tournament Victory Chances Over Time',
              padding: 5,
          },
        },
    };

    return (
        <div className='line-graph'>
            <Line plugins={props.plugins} options={options} data={props.data ? props.data : data} />
        </div>
    )
}