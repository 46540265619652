import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

// const TRACKING_ID = 'UA-230577039-2';
// ReactGA.initialize(TRACKING_ID);

import HomePage from './home'
import WorldCup2023 from './follow'
import Candidates2024 from './Candidates2024'
import WCC2024 from './WCC2023'
import FollowPage from './follow'
import OpeningsPage from './openings'
import AboutPage from './about'

function NavBar() {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const anim = {
        animationName: 'rainbow',
        animationDuration: '.75s',
        animationIterationCount: '3'
    };

    return (
        <div className='NavBar'>
            <Router>
                <nav>
                    <h2>Chess Assess</h2>
                    <div className = 'nav-holder'>
                        {/* <ul className='nav-links'>
                            <li>
                                <Link to='/'><h2>Home</h2></Link>
                            </li>
                            <li>
                                <Link to='about'><h2>About</h2></Link>
                            </li>
                            <li>
                                <Link to='openings'><h2>Opening Trainer</h2></Link>
                            </li>
                        </ul> */}
                        <ul className='nav-links'>
                            <li>
                                <Link to='/'><h2>Home</h2></Link>
                            </li>
                            <li>
                                <Link to='worldchampionship'><h2>World Championship 2023</h2></Link>
                            </li>
                            <li>
                                <Link to='worldcup'><h2>World Cup 2023</h2></Link>
                            </li>
                            <li>
                                <Link to='candidates'><h2 style={{...anim}}>Candidates 2024</h2></Link>
                            </li>
                            <li>
                                <Link to='openings'><h2>Opening Trainer</h2></Link>
                            </li>
                        </ul>
                    </div>
                    <button style={{
                        padding: '1vmin',
                    }}
                            onClick={() => {
                                // console.log("posting");
                                // axios.post('/tournament_data', { name: "Womens Candidates Final" })
                                // .then(res => {
                                //   console.log("response: \n" + res);
                                // })
                                document.documentElement.style.setProperty('--hue', (Math.floor(Math.random() * 360) - 0) + 'deg');
                                document.documentElement.style.setProperty('--sat', (Math.floor(Math.random() * 60) + 50) + '%');
                                ReactGA.event({
                                    category: 'Navigation Bar',
                                    action: 'Changed Color'
                                });
                            }}>
                        <h3 style={{
                            fontSize: 'medium'
                        }}>Fun</h3>
                    </button>
                </nav>
                <Routes>
                    <Route path='/' element={<AboutPage />} />
                    <Route path='about' element={<AboutPage />} />
                    <Route path='follow' element={<Candidates2024 />} />
                    <Route path='openings' element={<OpeningsPage />} />
                    <Route path='candidates' element={<Candidates2024 />} />
                    <Route path='worldchampionship' element={<WCC2024 />} />
                    <Route path='worldcup' element={<WorldCup2023 />} />
                </Routes>
            </Router>
        </div>
    )
}

export default NavBar;