import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import erfc from 'math-erfc';
import Bracket from './KnockoutBracket';
import Table from './KnockoutStandings';

import '../App.css';

export default function KnockoutTournament(props) {

    const unitStart = 'calc(var(--unit) * ';
    const unitEnd = ')';

    // const isPortrait = useMediaQuery({query: '(orientation: portrait)'});
    const isPortrait = false;

    function getBracketSize(numPlayers) {
        return Math.pow(2, Math.ceil(Math.log2(numPlayers)));
    }

    function getSeedings(numPlayers) {
        var rounds = Math.log(numPlayers)/Math.log(2)-1;
        var pls = [1,2];
        for(var i=0;i<rounds;i++){
            pls = nextLayer(pls);
        }
        return pls;
        function nextLayer(pls){
            var out=[];
            var length = pls.length*2+1;
            pls.forEach(function(d){
            out.push(d);
            out.push(length-d);
            });
            return out;
        }
    }

    const drawWeights = [
        -0.1319925,
        0.001823399,
        -0.0003408588,
        0.002164263,
        -0.0019374980,
        0.5328431,
        -0.501454589,
        -0.007406910,
        -0.02810264
    ]
    const whiteWeights = [
        -2.8472784,
        0.004396867,
        -0.0029729183,
        0.007369797,
        -0.0004282089,
        1.1312987,
        -0.001756741,
        -0.005103267,
        -0.01124505
    ]

    function regressionChances(whiteRating, blackRating) {
        const x = [
            1,
            whiteRating,
            blackRating,
            whiteRating - blackRating,
            Math.abs(whiteRating - blackRating),
            0.2,
            0.2,
            0,
            78,
        ]
        var w = 0;
        var d = 0;
        for (var i = 0; i < x.length; i++) {
            w += x[i] * whiteWeights[i];
            d += x[i] * drawWeights[i];
        }
        var pW = Math.exp(w);
        var pD = Math.exp(d);
        var pB = 1;
        const total = pW + pD + pB;
        pW /= total;
        pD /= total;
        pB /= total;
        return [pW, pD, pB];
    }

    function eloNormal(diff) {
        return erfc(-diff / ((2000 / 7) * Math.sqrt(2))) / 2;
    }

    function eloPerPawnAtElo(elo) {
        return Math.exp(elo / 1020) * 26.59;
    }

    function eloChances(r1, r2, type) {
        const diff = r1 - r2;
        const avg = (r1 + r2) / 2;
        const eloPerPawn = eloPerPawnAtElo(avg);
        const eloShift = eloPerPawn * (type == 'blitz' ? .2 : type == 'rapid' ? .4 : .6);
        const expectedScore = eloNormal(diff);
        const pWin = eloNormal(diff - eloShift);
        const pDraw = (expectedScore - pWin) * 2;
        const pLoss = 1 - pWin - pDraw;
        return [pWin, pDraw, pLoss]
    }

    function getEloChances(whiteRating, blackRating, type) {
        const whiteAdv = 35;
        if (whiteRating + whiteAdv > blackRating) {
            return eloChances(blackRating, whiteRating + whiteAdv, type).reverse();
        } else {
            return eloChances(whiteRating + whiteAdv, blackRating, type);
        }
    }

    useEffect(() => {
        // console.log(getEloChances(2700, 2700))
    }, [])

    function individualGameChances(whiteRating, blackRating, type) {
        return getEloChances(whiteRating, blackRating, type);
        // return regressionChances(whiteRating, blackRating);
    }

    function matchWinChances(myRating, opponentRating) {
        const results = [
            1, .5, 0
        ]
        var wins = 0;
        const sumList = (list) => {
            var sum = 0;
            for (var i = 0; i < list.length; i++) {
                sum += list[i];
            }
            return sum;
        }
        // var p = 0;
        const probLoop = (depth, itrs, format) => {
            var p = 0;
            for (var i = 0; i < results.length; i++) {
                if (depth == format.count) {
                    const chancesWhite = individualGameChances(myRating, opponentRating, format.type);
                    const chancesBlack = individualGameChances(opponentRating, myRating, format.type).reverse();
                    var newItrs = itrs.concat([results[i]]);
                    const sum = sumList(newItrs);
                    if (sum >= format.count / 2) {
                        var c = 1;
                        for (var j = 0; j < newItrs.length; j++) {
                            var chances = j % 2 == 0 ? chancesWhite : chancesBlack;
                            if (newItrs[j] == 1) {
                                c *= chances[0];
                            } else if (newItrs[j] == .5) {
                                c *= chances[1];
                            } else {
                                c *= chances[2];
                            }
                        }
                        if (sum == props.format.count / 2) {
                            // p += c / 2;
                            if (format.tieBreaks != null) {
                                p += c * probLoop(1, [], format.tieBreaks);
                            } else {
                                p += c / 2;
                            }
                        } else {
                            p += c;
                        }
                    }
                } else {
                    p += probLoop(depth + 1, itrs.concat([results[i]]), format);
                }
            }
            return p;
        }
        const prob = probLoop(1, [], props.format);
        return prob;
    }

    const [chancesMatrix, setChancesMatrix] = useState(createChancesMatrix());


    function findPossibleOpponents(seed, round) {
        const bracketSize = Math.pow(2, Math.ceil(Math.log2(props.players.length)));
        const seedings = getSeedings(bracketSize);
        const num = Math.pow(2, round);
        var seedIndex = seedings.indexOf(seed);
        // console.log("init " + seedings[seedIndex])
        for (var i = 0; i < round; i++) {
            const c0 = Math.pow(2, i)
            const c = Math.pow(2, i + 1)
            var oppIndex = seedIndex % (c) == 0 ? seedIndex + c0 : seedIndex - c0;
            if (seedings[oppIndex] < seedings[seedIndex]) seedIndex = oppIndex;
            // console.log("step " + i + "::  " + c + " " + oppIndex + ":" + seedings[oppIndex] + "," + " -> " + seedIndex + ":" + seedings[seedIndex])
        }
        // console.log(seedIndex + ": " + seedings[seedIndex]);
        const opponentIndex = seedIndex % (2 * num) == 0 ? seedIndex + num : seedIndex - num;
        // console.log("opponent in round " + round + ": " + opponentIndex + "." + seedings[opponentIndex])
        var opponents = [];
        // console.log(props.players[seed - 1].name + " has " + num + " possible opponents:")
        for (var i = 0; i < num; i++) {
            var s = opponentIndex + i;
            if (seedings[s] <= props.players.length) {
                opponents.push(seedings[s] - 1);
                // console.log("\t" + props.players[seedings[s] - 1].name)
            }
        }
        return opponents;
    }

    function createChancesMatrix(picks=[]) {
        const bracketSize = Math.pow(2, Math.ceil(Math.log2(props.players.length)));
        const numRounds = Math.log2(bracketSize);
        var matrix = [];
        for (var i = 0; i < props.players.length; i++) {
            var row = [i + 1, 1.0];
            for (var j = 0; j < numRounds; j++) {
                row.push(0);
            }
            matrix.push(row);
        }
        for (var round = 0; round < numRounds; round++) {
            for (var i = 0; i < matrix.length; i++) {
                if (picks.length > 0) {
                    if (picks[i].makeItPast >= round) {
                        matrix[i][2 + round] = 1;
                        continue;
                    }
                    var cantPassRound = false;
                    for (var j = 0; j <= round; j++) {
                        if (picks[i].cantPass.includes(j)) {
                            cantPassRound = true;
                        }
                    }
                    if (cantPassRound) {
                        matrix[i][2 + round] = 0;
                        continue;
                    }
                }
                var possibleOpponents = findPossibleOpponents(i + 1, round);
                var chanceOfWinningRound = 0;
                for (var j = 0; j < possibleOpponents.length; j++) {
                    chanceOfWinningRound += matrix[possibleOpponents[j]][2 + round - 1]
                                            * matchWinChances(props.players[i].rating, props.players[possibleOpponents[j]].rating);
                }
                if (possibleOpponents.length == 0) matrix[i][2 + round] = 1;
                else matrix[i][2 + round] = matrix[i][2 + round - 1] * chanceOfWinningRound;
            }
        }
        return matrix;
    }

    const [activeTab, setActiveTab] = useState('bracket')

    const border = {
        border: unitStart + .2 + unitEnd + ' solid var(--accent)',
        fontSize: unitStart + 1.5 + unitEnd,
    }
    const noBorder = {
        border: unitStart + .2 + unitEnd + ' solid var(--background)',
        fontSize: unitStart + 1.5 + unitEnd,
    }

    return (
        <div className='knockout-tournament-container'>
            <div>
                <h2 className='knockout-title'>
                    {props.name}
                </h2>
                <button className='help'
                    style={
                        {
                            margin: unitStart + 1.5 + unitEnd,
                        }
                    }
                >
                    ?
                    <span className='help-text'>
                        {
                            "Welcome to the Chess Assess coverage of the FIDE World Cup 2023!" + "\n" + 
                            (isPortrait ? "To see the tournament bracket, please switch to landscape mode." + "\n" : "") + 
                            "Hover over players on the bracket to see their chance of making it to each round!" + "\n" + 
                            "Or you can make your own picks and see how that affects their chances." + "\n" + 
                            "You can also check out the table of every player's chance of making it to each round."
                        }
                    </span>
                </button>
            </div>
            {
                !isPortrait
                ?
                <div className='knockout-tabs'>
                    <button className='knockout-tab'
                        style={activeTab == 'bracket' ? border : noBorder}
                        onClick={() => {setActiveTab('bracket')}}
                    >
                        {'Bracket'}
                    </button>
                    <button className='knockout-tab'
                        style={activeTab == 'table' ? border : noBorder}
                        onClick={() => {setActiveTab('table')}}
                    >
                        {'Table'}
                    </button>
                </div>
                :
                <div></div>
            }
            
            {
                isPortrait || activeTab == 'table'
            ?
                <Table 
                    players={props.players}
                    format={props.format}
                    chancesMatrix={chancesMatrix}
                />
            :
                activeTab == 'bracket'
            ?
                <Bracket 
                    players={props.players}
                    n={props.n}
                    chancesMatrix={chancesMatrix}
                    createChancesMatrix={createChancesMatrix}
                    findPossibleOpponents={findPossibleOpponents}
                />
            :
                <div></div>
            }
        </div>
    )
}