import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chess } from 'chess.js';
import ReactGA from 'react-ga';

import candidatesProbs from '../probabilities.txt';

import wccProbs1 from '../WCCProbs2023/wccprobs1.txt';
import wccProbs2 from '../WCCProbs2023/wccprobs2.txt';
import wccProbs3 from '../WCCProbs2023/wccprobs3.txt';
import wccProbs4 from '../WCCProbs2023/wccprobs4.txt';
import wccProbs5 from '../WCCProbs2023/wccprobs5.txt';
import wccProbs6 from '../WCCProbs2023/wccprobs6.txt';
import wccProbs7 from '../WCCProbs2023/wccprobs7.txt';
import wccProbs8 from '../WCCProbs2023/wccprobs8.txt';
import wccProbs9 from '../WCCProbs2023/wccprobs9.txt';
import wccProbs10 from '../WCCProbs2023/wccprobs10.txt';
import wccProbs11 from '../WCCProbs2023/wccprobs11.txt';
import wccProbs12 from '../WCCProbs2023/wccprobs12.txt';
import wccProbs13 from '../WCCProbs2023/wccprobs13.txt';
import wccProbs14 from '../WCCProbs2023/wccprobs14.txt';

import wcc2021Probs1 from '../wcc2021probs/wcc_probs_2021_1.txt';
import wcc2021Probs2 from '../wcc2021probs/wcc_probs_2021_2.txt';
import wcc2021Probs3 from '../wcc2021probs/wcc_probs_2021_3.txt';
import wcc2021Probs4 from '../wcc2021probs/wcc_probs_2021_4.txt';
import wcc2021Probs5 from '../wcc2021probs/wcc_probs_2021_5.txt';
import wcc2021Probs6 from '../wcc2021probs/wcc_probs_2021_6.txt';
import wcc2021Probs7 from '../wcc2021probs/wcc_probs_2021_7.txt';
import wcc2021Probs8 from '../wcc2021probs/wcc_probs_2021_8.txt';
import wcc2021Probs9 from '../wcc2021probs/wcc_probs_2021_9.txt';
import wcc2021Probs10 from '../wcc2021probs/wcc_probs_2021_10.txt';
import wcc2021Probs11 from '../wcc2021probs/wcc_probs_2021_11.txt';

import Board from './Board copy';
import StandingsRow from './StandingsRow';
import Tournament from './Tournament';
import TournamentParent from './TournamentParent';
import KnockoutTournament from './KnockoutTournament';

const depth = 30;

const wccTimeControl = [
    {
        moves: 40,
        time: 120 * 60,
        increment: 0,
    },
    {
        moves: 20,
        time: 60 * 60,
        increment: 0,
    },
    {
        moves: Infinity,
        time: 15 * 60,
        increment: 30,
    },
]

const worldCupClassical = [
    {
        moves: 40,
        time: 90 * 60,
        increment: 30,
    },
    {
        moves: Infinity,
        time: 30 * 60,
        increment: 30,
    },
]
const worldCupTB_25min= [
    {
        moves: Infinity,
        time: 25 * 60,
        increment: 10,
    },
]
const worldCupTB_10min = [
    {
        moves: Infinity,
        time: 10 * 60,
        increment: 10,
    },
]
const worldCupTB_5min = [
    {
        moves: Infinity,
        time: 5 * 60,
        increment: 3,
    },
]
const worldCupTB_3min = [
    {
        moves: Infinity,
        time: 3 * 60,
        increment: 2,
    },
]

const worldCupMatchFormat = {
    count: 2,
    timeControl: worldCupClassical,
    type: 'classical',
    tieBreaks: {
        count: 2,
        timeControl: worldCupTB_25min,
        type: 'rapid',
        // tieBreaks: {
        //     count: 2,
        //     timeControl: worldCupTB_10min,
        //     type: 'rapid',
        //     tieBreaks: {
        //         count: 2,
        //         timeControl: worldCupTB_5min,
        //         type: 'blitz',
        //         tieBreaks: {
        //             count: 2,
        //             timeControl: worldCupTB_3min,
        //             type: 'blitz',
        //             tieBreaks: null
        //         }
        //     }
        // }
    }
}

const miscTournamentData = {
    // "FIDE World Chess Championship 2023": {
    //     order: 2,
    //     currentRound: 14,
    //     type: 'match',
    //     comingSoon: false,
    //     display: true,
    //     playerInfo: [
    //         {
    //             name: "Nepo",
    //             score: 6.5,
    //         },
    //         {
    //             name: "Ding",
    //             score: 6.5,
    //         }
    //     ],
    //     playerColors: {
    //         "Nepo": "#7f6fff",
    //         "Ding": "#ff6f6f",
    //     },
    //     probInfo: [
    //         {
    //             data: wccProbs1,
    //             N: 10000,
    //         },
    //         {
    //             data: wccProbs2,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs3,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs4,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs5,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs6,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs7,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs8,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs9,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs10,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs11,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs12,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs13,
    //             N: 10000
    //         },
    //         {
    //             data: wccProbs14,
    //             N: 10000
    //         }
    //     ],
    //     graph: true,
    //     timeControl: wccTimeControl,
    // },
    // "FIDE World Cup 2023": {
    //     order: 1,
    //     currentRound: 1,
    //     type: 'knockout',
    //     comingSoon: false,
    //     display: true,
    //     playerInfo:[
    //         {},{},{}
    //     ],
    //     playerColors: {

    //     },
    //     probInfo: [],
    //     graph: false,
    //     timeControl: wccTimeControl
    // }
};

export default function Follow() {

    const [tournamentData, setTournamentData] = useState([]);
    
    const [updater, setUpdater] = useState(false);

    const [updateRate, setUpdateRate] = useState(10 * 1000);

    useEffect(() => {
        // getTournamentData();
    }, [])

    // useEffect(() => {
    //     if (updater) {
    //         // console.log("calling update")
    //         const timer = setTimeout(getTournamentData, updateRate);
    //         return () => {
    //             clearTimeout(timer);
    //         }
    //     }
    // }, [tournamentData]);

    // function updaterClock() {
    //     setUpdater(!updater);
    //     setTimeout(updaterClock, updateRate);
    // }

    const worldCupPlayers = [
        { name: 'Magnus Carlsen', countryCode: 'NO', rating: 2835 },
        { name: 'Hikaru Nakamura', countryCode: 'US', rating: 2787 },
        { name: 'Fabiano Caruana', countryCode: 'US', rating: 2782 },
        { name: 'Ian Nepomniachtchi', countryCode: 'RU', rating: 2779 },
        { name: 'Anish Giri', countryCode: 'NL', rating: 2775 },
        { name: 'Wesley So', countryCode: 'US', rating: 2769 },
        { name: 'Teimour Radjabov', countryCode: 'AZ', rating: 2747 },
        { name: 'Gukesh D', countryCode: 'IN', rating: 2744 },
        { name: 'Shakhriyar Mamedyarov', countryCode: 'AZ', rating: 2742 },
        { name: 'Maxime Vachier-Lagrave', countryCode: 'FR', rating: 2739 },
        { name: 'Leinier Dominguez Perez', countryCode: 'US', rating: 2739 },
        { name: 'Alexander Grischuk', countryCode: 'RU', rating: 2736 },
        { name: 'Yu Yangyi', countryCode: 'CN', rating: 2735 },
        { name: 'Jan-Krzysztof Duda', countryCode: 'PL', rating: 2732 },
        { name: 'Le Quang Liem', countryCode: 'VN', rating: 2728 },
        { name: 'Wei Yi', countryCode: 'CN', rating: 2726 },
        { name: 'Nodirbek Abdusattorov', countryCode: 'UZ', rating: 2725 },
        { name: 'Nikita Vitiugov', countryCode: 'RU', rating: 2720 },
        { name: 'Parham Maghsoodloo', countryCode: 'IR', rating: 2719 },
        { name: 'Vidit Santosh Gujrathi', countryCode: 'IN', rating: 2719 },
        { name: 'Daniil Dubov', countryCode: 'RU', rating: 2716 },
        { name: 'Sam Shankland', countryCode: 'US', rating: 2711 },
        { name: 'Arjun Erigaisi', countryCode: 'IN', rating: 2710 },
        { name: 'Wang Hao', countryCode: 'CN', rating: 2709 },
        { name: 'Francisco Vallejo Pons', countryCode: 'ES', rating: 2706 },
        { name: 'Bassem Amin', countryCode: 'EG', rating: 2694 },
        { name: 'Kirill Shevchenko', countryCode: 'RO', rating: 2694 },
        { name: 'Jorden Van Foreest', countryCode: 'NL', rating: 2693 },
        { name: 'Bogdan-Daniel Deac', countryCode: 'RO', rating: 2693 },
        { name: 'David Antón Guijarro', countryCode: 'ES', rating: 2691 },
        { name: 'R Praggnanandhaa', countryCode: 'IN', rating: 2690 },
        { name: 'Vincent Keymer', countryCode: 'DE', rating: 2690 },
        { name: 'Amin Tabatabaei', countryCode: 'IR', rating: 2689 },
        { name: 'David Navara', countryCode: 'CZ', rating: 2689 },
        { name: 'Ray Robson', countryCode: 'US', rating: 2689 },
        { name: 'Nihal Sarin', countryCode: 'IN', rating: 2688 },
        { name: 'Peter Svidler', countryCode: 'RU', rating: 2688 },
        { name: 'Alexey Sarana', countryCode: 'RS', rating: 2685 },
        { name: 'Nils Grandelius', countryCode: 'SE', rating: 2684 },
        { name: 'Andrey Esipenko', countryCode: 'RU', rating: 2683 },
        { name: 'David Howell', countryCode: 'GB', rating: 2677 },
        { name: 'Vladimir Fedoseev', countryCode: 'RU', rating: 2676 },
        { name: 'Radoslaw Wojtaszek', countryCode: 'PL', rating: 2676 },
        { name: 'Andrei Volokitin', countryCode: 'UA', rating: 2674 },
        { name: 'Matthias Bluebaum', countryCode: 'DE', rating: 2672 },
        { name: 'Alexander Donchenko', countryCode: 'DE', rating: 2668 },
        { name: 'Boris Gelfand', countryCode: 'IL', rating: 2668 },
        { name: 'Anton Korobov', countryCode: 'UA', rating: 2667 },
        { name: 'Vasyl Ivanchuk', countryCode: 'UA', rating: 2667 },
        { name: 'Ruslan Ponomariov', countryCode: 'UA', rating: 2664 },
        { name: 'Ivan Cheparinov', countryCode: 'BG', rating: 2663 },
        { name: 'Etienne Bacrot', countryCode: 'FR', rating: 2662 },
        { name: 'Salem Saleh', countryCode: 'AE', rating: 2661 },
        { name: 'Gadir Guseinov', countryCode: 'AZ', rating: 2661 },
        { name: 'Javokhir Sindarov', countryCode: 'UZ', rating: 2659 },
        { name: 'Ivan Saric', countryCode: 'HR', rating: 2657 },
        { name: 'S. L. Narayanan', countryCode: 'IN', rating: 2656 },
        { name: 'Jaime Santos Latasa', countryCode: 'ES', rating: 2656 },
        { name: 'Jules Moussard', countryCode: 'FR', rating: 2654 },
        { name: 'Laurent Fressinet', countryCode: 'FR', rating: 2652 },
        { name: 'Alexandr Predke', countryCode: 'RS', rating: 2651 },
        { name: 'Awonder Liang', countryCode: 'US', rating: 2649 },
        { name: 'Benjámin Gledura', countryCode: 'HU', rating: 2645 },
        { name: 'Thai Dai Van Nguyen', countryCode: 'CZ', rating: 2642 },
        { name: 'Aryan Tari', countryCode: 'NO', rating: 2641 },
        { name: 'Nodirbek Yakubboev', countryCode: 'UZ', rating: 2640 },
        { name: 'Mustafa Yılmaz', countryCode: 'TR', rating: 2639 },
        { name: 'Rauf Mamedov', countryCode: 'AZ', rating: 2636 },
        { name: 'Nijat Abasov', countryCode: 'AZ', rating: 2632 },
        { name: 'Vasif Durarbayli', countryCode: 'AZ', rating: 2631 },
        { name: 'Georg Meier', countryCode: 'UY', rating: 2629 },
        { name: 'Yuriy Kuzubov', countryCode: 'UA', rating: 2628 },
        { name: 'Rasmus Svane', countryCode: 'DE', rating: 2625 },
        { name: 'Markus Ragger', countryCode: 'AT', rating: 2624 },
        { name: 'Jonas Buhl Bjerre', countryCode: 'DK', rating: 2624 },
        { name: 'Anton Demchenko', countryCode: 'RU', rating: 2623 },
        { name: 'Aleksandar Indjic', countryCode: 'RS', rating: 2619 },
        { name: 'Frederik Svane', countryCode: 'DE', rating: 2618 },
        { name: 'Dmitrij Kollars', countryCode: 'DE', rating: 2618 },
        { name: 'Cristobal Henriquez Villagra', countryCode: 'CL', rating: 2618 },
        { name: 'Eduardo Iturrizaga Bonelli', countryCode: 'ES', rating: 2617 },
        { name: 'Ferenc Berkes', countryCode: 'HU', rating: 2615 },
        { name: 'Mateusz Bartel', countryCode: 'PL', rating: 2614 },
        { name: 'David Paravyan', countryCode: 'RU', rating: 2612 },
        { name: 'Daniele Vocaturo', countryCode: 'IT', rating: 2609 },
        { name: 'Eric Hansen', countryCode: 'CA', rating: 2609 },
        { name: 'Jerguš Pecháč', countryCode: 'SK', rating: 2608 },
        { name: 'Ante Brkic', countryCode: 'HR', rating: 2608 },
        { name: 'Eltaj Safarli', countryCode: 'AZ', rating: 2607 },
        { name: 'Mikhail Antipov', countryCode: 'RU', rating: 2606 },
        { name: 'Christopher Yoo', countryCode: 'US', rating: 2606 },
        { name: 'Abhimanyu Puranik', countryCode: 'IN', rating: 2605 },
        { name: 'Axel Bachmann', countryCode: 'PY', rating: 2604 },
        { name: 'Alexandr Fier', countryCode: 'BR', rating: 2604 },
        { name: 'Niclas Huschenbeth', countryCode: 'DE', rating: 2603 },
        { name: 'Denis Kadric', countryCode: 'ME', rating: 2601 },
        { name: 'Daniel Dardha', countryCode: 'BE', rating: 2600 },
        { name: 'Maxime Lagarde', countryCode: 'FR', rating: 2599 },
        { name: 'Shamsiddin Vokhidov', countryCode: 'UZ', rating: 2597 },
        { name: 'Kacper Piorun', countryCode: 'PL', rating: 2597 },
        { name: 'Ahmed Adly', countryCode: 'EG', rating: 2596 },
        { name: 'Ilia Smirin', countryCode: 'IL', rating: 2595 },
        { name: 'Carlos Daniel Albornoz Cabrera', countryCode: 'CU', rating: 2591 },
        { name: 'Velimir Ivic', countryCode: 'RS', rating: 2590 },
        { name: 'Rinat Jumabayev', countryCode: 'KZ', rating: 2589 },
        { name: 'Sergei Azarov', countryCode: 'FIDE', rating: 2589 },
        { name: 'Nidjat Mamedov', countryCode: 'AZ', rating: 2589 },
        { name: 'Adhiban Baskaran', countryCode: 'IN', rating: 2587 },
        { name: 'Dimitrios Mastrovasilis', countryCode: 'GR', rating: 2587 },
        { name: 'Aydin Suleymanli', countryCode: 'AZ', rating: 2586 },
        { name: 'Daniel Fridman', countryCode: 'DE', rating: 2586 },
        { name: 'Vahap Şanal', countryCode: 'TR', rating: 2585 },
        { name: 'Luis Paulo Supi', countryCode: 'BR', rating: 2582 },
        { name: 'Peter Michalik', countryCode: 'CZ', rating: 2582 },
        { name: 'Timur Gareyev', countryCode: 'US', rating: 2581 },
        { name: 'Temur Kuybokarov', countryCode: 'AU', rating: 2581 },
        { name: 'Bardiya Daneshvar', countryCode: 'IR', rating: 2577 },
        { name: 'Szymon Gumularz', countryCode: 'PL', rating: 2577 },
        { name: 'Valentin Dragnev', countryCode: 'AT', rating: 2576 },
        { name: 'Tin Jingyao', countryCode: 'SG', rating: 2573 },
        { name: 'Bobby Cheng', countryCode: 'AU', rating: 2572 },
        { name: 'Viktor Erdős', countryCode: 'HU', rating: 2571 },
        { name: 'Emre Can', countryCode: 'TR', rating: 2570 },
        { name: 'Fernando Peralta', countryCode: 'AR', rating: 2568 },
        { name: 'Vugar Asadli', countryCode: 'AZ', rating: 2567 },
        { name: 'Tomas Laurusas', countryCode: 'LT', rating: 2566 },
        { name: 'Karthik Venkataraman', countryCode: 'IN', rating: 2565 },
        { name: 'Levan Pantsulaia', countryCode: 'GE', rating: 2564 },
        { name: 'Harsha Bharathakoti', countryCode: 'IN', rating: 2563 },
        { name: 'Gregory Kaidanov', countryCode: 'US', rating: 2563 },
        { name: 'Mikheil Mchedlishvili', countryCode: 'GE', rating: 2561 },
        { name: 'Lê Tuấn Minh', countryCode: 'VN', rating: 2557 },
        { name: 'Arseniy Nesterov', countryCode: 'FIDE', rating: 2554 },
        { name: 'Luis Ernesto Quesada Pérez', countryCode: 'CU', rating: 2553 },
        { name: 'Grzegorz Nasuta', countryCode: 'PL', rating: 2552 },
        { name: 'Misratdin Iskandarov', countryCode: 'AZ', rating: 2551 },
        { name: 'Vladimir Baklan', countryCode: 'UA', rating: 2550 },
        { name: 'Ori Kobo', countryCode: 'IL', rating: 2548 },
        { name: 'Igor Janik', countryCode: 'PL', rating: 2548 },
        { name: 'Mahammad Muradli', countryCode: 'AZ', rating: 2546 },
        { name: 'Vitaliy Bernadskiy', countryCode: 'UA', rating: 2545 },
        { name: 'Emilio Cordova', countryCode: 'PE', rating: 2542 },
        { name: 'Ivan Ivanisevic', countryCode: 'RS', rating: 2536 },
        { name: 'Adham Fawzy', countryCode: 'EG', rating: 2533 },
        { name: 'Zdenko Kozul', countryCode: 'HR', rating: 2532 },
        { name: 'Stamatis Kourkoulos-Arditis', countryCode: 'GR', rating: 2531 },
        { name: 'Xu Yinglun', countryCode: 'CN', rating: 2531 },
        { name: 'Felix Blohberger', countryCode: 'AT', rating: 2522 },
        { name: 'Lance Henderson de La Fuente', countryCode: 'AD', rating: 2517 },
        { name: 'Ivan Schitco', countryCode: 'MD', rating: 2507 },
        { name: 'Susanto Megaranto', countryCode: 'ID', rating: 2505 },
        { name: 'Luka Paichadze', countryCode: 'GE', rating: 2501 },
        { name: 'Ediz Gurel', countryCode: 'TR', rating: 2500 },
        { name: 'Alisher Suleymenov', countryCode: 'KZ', rating: 2499 },
        { name: 'Santiago Avila Pavas', countryCode: 'CO', rating: 2499 },
        { name: 'Evandro Barbosa', countryCode: 'BR', rating: 2493 },
        { name: 'Pablo Ismael Acosta', countryCode: 'AR', rating: 2485 },
        { name: 'Abdulla Gadimbayli', countryCode: 'AZ', rating: 2483 },
        { name: 'Yago Santiago', countryCode: 'BR', rating: 2481 },
        { name: 'Goh Wei Ming', countryCode: 'SG', rating: 2473 },
        { name: 'Pablo Salinas Herrera', countryCode: 'CL', rating: 2468 },
        { name: 'Fy Rakotomaharo', countryCode: 'MG', rating: 2463 },
        { name: 'Gábor Nagy', countryCode: 'HU', rating: 2461 },
        { name: 'Sumiya Bilguun', countryCode: 'MN', rating: 2457 },
        { name: 'Luis Fernando Ibarra Chami', countryCode: 'MX', rating: 2448 },
        { name: 'Huang Renjie', countryCode: 'CN', rating: 2445 },
        { name: 'Josiah Stearman', countryCode: 'US', rating: 2444 },
        { name: 'Roberto Carlos Sanchez Alvarez', countryCode: 'PA', rating: 2444 },
        { name: 'Carlos Matamoros Franco', countryCode: 'EC', rating: 2441 },
        { name: 'Mark Paragua', countryCode: 'PH', rating: 2438 },
        { name: 'L M S T De Silva', countryCode: 'LK', rating: 2433 },
        { name: 'Mohammad Fahad Rahman', countryCode: 'BD', rating: 2428 },
        { name: 'Trần Thanh Tú', countryCode: 'JP', rating: 2420 },
        { name: 'Abdelrahman Hesham', countryCode: 'EG', rating: 2414 },
        { name: 'Pouria Darini', countryCode: 'IR', rating: 2412 },
        { name: 'Ganzorig Amartuvshin', countryCode: 'MN', rating: 2407 },
        { name: 'Daniel Cawdery', countryCode: 'ZA', rating: 2388 },
        { name: 'Enamul Hossain', countryCode: 'BD', rating: 2386 },
        { name: 'Lee Jun Hyeok', countryCode: 'KR', rating: 2378 },
        { name: 'Amir Zaibi', countryCode: 'TN', rating: 2375 },
        { name: 'Gianmarco Leiva', countryCode: 'PE', rating: 2374 },
        { name: 'Basheer Al Qudaimi', countryCode: 'YE', rating: 2374 },
        { name: 'Kareim Wageih', countryCode: 'EG', rating: 2369 },
        { name: 'Prin Laohawirapap', countryCode: 'TH', rating: 2353 },
        { name: 'Mohamed Tissir', countryCode: 'MA', rating: 2347 },
        { name: 'Bernardo Roselli', countryCode: 'UY', rating: 2347 },
        { name: 'Leonel Figueredo Losada', countryCode: 'MX', rating: 2345 },
        { name: 'Rodwell Makoto', countryCode: 'ZW', rating: 2312 },
        { name: 'Lim Zhuo Ren', countryCode: 'MY', rating: 2310 },
        { name: 'Chitumbo Mwali', countryCode: 'ZM', rating: 2304 },
        { name: 'Malek Koniahli', countryCode: 'SY', rating: 2296 },
        { name: 'Amro El Jawich', countryCode: 'LB', rating: 2288 },
        { name: 'Zhang Yuanchen', countryCode: 'CA', rating: 2281 },
        { name: 'Eldiar Orozbaev', countryCode: 'KG', rating: 2281 },
        { name: 'Alisher Karimov', countryCode: 'TJ', rating: 2273 },
        { name: 'Oluwafemi Balogun', countryCode: 'NG', rating: 2235 },
        { name: 'Djabri Massinas', countryCode: 'DZ', rating: 2231 },
        { name: 'Haruna Nsubuga', countryCode: 'UG', rating: 2223 },
        { name: 'Austin Yang Ching-Wei', countryCode: 'TW', rating: 2212 },
        { name: 'Providence Oatlhotse', countryCode: 'BW', rating: 2199 },
        { name: 'Dante Beukes', countryCode: 'NA', rating: 2180 },
        { name: 'Deng Cypriano Rehan', countryCode: 'SS', rating: 2147 },
        { name: 'Rupesh Jaiswal', countryCode: 'NP', rating: 2122 },
        { name: 'Aydagnuhem Gezachew Abera', countryCode: 'ET', rating: 2121 },
        { name: 'Nay Lin Tum', countryCode: 'MM', rating: 2055 },
        { name: 'Yousef Alhassadi', countryCode: 'LY', rating: 2030 }
      ];

    const womensWorldCupPlayers = [
        { name: "Ju Wenjun", countryCode: "CN", rating: 2564 },
        { name: "Aleksandra Goryachkina", countryCode: "RU", rating: 2557 },
        { name: "Humpy Koneru", countryCode: "IN", rating: 2553 },
        { name: "Kateryna Lagno", countryCode: "RU", rating: 2552 },
        { name: "Alexandra Kosteniuk", countryCode: "CH", rating: 2532 },
        { name: "Tan Zhongyi", countryCode: "CN", rating: 2523 },
        { name: "Nana Dzagnidze", countryCode: "GE", rating: 2511 },
        { name: "Mariya Muzychuk", countryCode: "UA", rating: 2511 },
        { name: "Anna Muzychuk", countryCode: "UA", rating: 2509 },
        { name: "Harika Dronavalli", countryCode: "IN", rating: 2500 },
        { name: "Zhu Jiner", countryCode: "CN", rating: 2498 },
        { name: "Polina Shuvalova", countryCode: "RU", rating: 2496 },
        { name: "Sarasadat Khademalsharieh", countryCode: "ES", rating: 2488 },
        { name: "Bella Khotenashvili", countryCode: "GE", rating: 2475 },
        { name: "Nino Batsiashvili", countryCode: "GE", rating: 2474 },
        { name: "Elisabeth Paehtz", countryCode: "DE", rating: 2471 },
        { name: "Bibisara Assaubayeva", countryCode: "KZ", rating: 2469 },
        { name: "Zhao Xue", countryCode: "CN", rating: 2457 },
        { name: "Meri Arabidze", countryCode: "GE", rating: 2451 },
        { name: "Gunay Mammadzada", countryCode: "AZ", rating: 2448 },
        { name: "Irina Krush", countryCode: "US", rating: 2447 },
        { name: "Yuliia Osmak", countryCode: "UA", rating: 2444 },
        { name: "Lela Javakhishvili", countryCode: "GE", rating: 2439 },
        { name: "Anna Ushenina", countryCode: "UA", rating: 2434 },
        { name: "Vaishali Rameshbabu", countryCode: "IN", rating: 2431 },
        { name: "Eline Roebers", countryCode: "NL", rating: 2419 },
        { name: "Irina Bulmaga", countryCode: "RO", rating: 2416 },
        { name: "Teodora Injac", countryCode: "RS", rating: 2415 },
        { name: "Nurgyul Salimova", countryCode: "BG", rating: 2409 },
        { name: "Leya Garifullina", countryCode: "FI", rating: 2408 },
        { name: "Hoang Thanh Trang", countryCode: "HU", rating: 2402 },
        { name: "Monika Socko", countryCode: "PL", rating: 2401 },
        { name: "Ulviyya Fataliyeva", countryCode: "AZ", rating: 2401 },
        { name: "Divya Deshmukh", countryCode: "CN", rating: 2400 },
        { name: "Olga Badelka", countryCode: "FI", rating: 2397 },
        { name: "Oliwia Kiołbasa", countryCode: "PL", rating: 2394 },
        { name: "Sophie Milliet", countryCode: "FR", rating: 2388 },
        { name: "Mai Narva", countryCode: "EE", rating: 2387 },
        { name: "Aleksandra Maltsevskaya", countryCode: "PL", rating: 2394 },
        { name: "Pauline Guichard", countryCode: "FR", rating: 2384 },
        { name: "Salome Melia", countryCode: "GE", rating: 2377 },
        { name: "Batkhuyag Munguntuul", countryCode: "MN", rating: 2374 },
        { name: "Stavroula Tsolakidou", countryCode: "GR", rating: 2373 },
        { name: "Xiao Yiyi", countryCode: "CN", rating: 2372 },
        { name: "Alina Bivol", countryCode: "FI", rating: 2371 },
        { name: "Deysi Cori", countryCode: "PE", rating: 2369 },
        { name: "Carissa Yip", countryCode: "US", rating: 2369 },
        { name: "Khanim Balajayeva", countryCode: "AZ", rating: 2368 },
        { name: "Nataliya Buksa", countryCode: "UA", rating: 2365 },
        { name: "Gulnar Mammadova", countryCode: "AZ", rating: 2357 },
        { name: "Karina Cyfka", countryCode: "PL", rating: 2356 },
        { name: "Medina Warda Aulia", countryCode: "ID", rating: 2355 },
        { name: "Govhar Beydullayeva", countryCode: "AZ", rating: 2355 },
        { name: "Candela Francisco", countryCode: "AR", rating: 2352 },
        { name: "Võ Thị Kim Phụng", countryCode: "VN", rating: 2351 },
        { name: "Deimante Daulyte-Cornette", countryCode: "FR", rating: 2348 },
        { name: "Anastasiya Rakhmangulova", countryCode: "UA", rating: 2347 },
        { name: "Klaudia Kulon", countryCode: "PL", rating: 2331 },
        { name: "Mobina Alinasab", countryCode: "IR", rating: 2329 },
        { name: "P. V. Nandhidhaa", countryCode: "IN", rating: 2329 },
        { name: "Mary Ann Gomes", countryCode: "IN", rating: 2326 },
        { name: "Marina Brunello", countryCode: "IT", rating: 2324 },
        { name: "Lisandra Teresa Ordaz Valdés", countryCode: "CU", rating: 2318 },
        { name: "Eva Repkova", countryCode: "SK", rating: 2312 },
        { name: "Nilufar Yakubbaeva", countryCode: "UZ", rating: 2311 },
        { name: "Jennifer Yu", countryCode: "US", rating: 2303 },
        { name: "Nutakki Priyanka", countryCode: "IN", rating: 2293 },
        { name: "Yaniela Forgas Moreno", countryCode: "CU", rating: 2293 },
        { name: "Yan Tianqi", countryCode: "CN", rating: 2277 },
        { name: "Julia Ryjanova", countryCode: "AU", rating: 2273 },
        { name: "Yerisbel Miranda Llanes", countryCode: "CU", rating: 2268 },
        { name: "Viktoria Radeva", countryCode: "BG", rating: 2263 },
        { name: "Nadya Toncheva", countryCode: "BG", rating: 2263 },
        { name: "Michalina Rudzinska", countryCode: "PL", rating: 2257 },
        { name: "Javiera Belén Gómez Barrera", countryCode: "CL", rating: 2256 },
        { name: "Marina Gajcin", countryCode: "RS", rating: 2251 },
        { name: "Janelle Mae Frayna", countryCode: "PH", rating: 2243 },
        { name: "Melissa Castrillon Gomez", countryCode: "CO", rating: 2235 },
        { name: "Nguyen Thi Mai Hung", countryCode: "VN", rating: 2229 },
        { name: "Turmunkh Munkhzul", countryCode: "MN", rating: 2226 },
        { name: "Gong Qianyun", countryCode: "SG", rating: 2223 },
        { name: "Maria Jose Campos", countryCode: "AR", rating: 2212 },
        { name: "Julia Alboredo", countryCode: "BR", rating: 2208 },
        { name: "Zhang Jilin", countryCode: "AU", rating: 2204 },
        { name: "Maili-Jade Ouellet", countryCode: "CA", rating: 2201 },
        { name: "Anapaola Borda Rodas", countryCode: "CO", rating: 2200 },
        { name: "Yuan Ye", countryCode: "CN", rating: 2188 },
        { name: "Kathiê Librelato", countryCode: "BR", rating: 2182 },
        { name: "Assel Serikbay", countryCode: "KZ", rating: 2139 },
        { name: "Mona Khaled", countryCode: "EG", rating: 2106 },
        { name: "Shahenda Wafa", countryCode: "EG", rating: 2093 },
        { name: "Lina Nassr", countryCode: "DZ", rating: 2071 },
        { name: "Mitra Asgharzadeh", countryCode: "IR", rating: 2033 },
        { name: "Tilsia Varela", countryCode: "VE", rating: 2028 },
        { name: "Puteri Munajjah Az-Zahraa Azhar", countryCode: "MY", rating: 2004 },
        { name: "Vyanla Punsalan", countryCode: "NZ", rating: 1989 },
        { name: "Anjum Noshin", countryCode: "BD", rating: 1944 },
        { name: "Dina Abdi Zineb", countryCode: "DZ", rating: 1858 },
        { name: "Wafia Darwish Al Maamari", countryCode: "AE", rating: 1850 },
        { name: "Charlize van Zyl", countryCode: "ZA", rating: 1839 },
        { name: "Nurai Sovetbekova", countryCode: "KG", rating: 1823 },
        { name: "Ferdous Jannatul", countryCode: "BD", rating: 1815 },
        { name: "Yamama Asif Abdula Al Fayyadh", countryCode: "IQ", rating: 1705 },
      ];

    async function getTournamentData() {
        // console.log("getting tournament data...")
        const data = await fetch('/tournament_data');
        const json = await data.json();
        const trimmed = [];
        var doUpdate = false;
        for (var key in miscTournamentData) {
            const t = json.find(x => x.name == key);
            if (t !== undefined) {
                if (tournamentData.length > 0) {
                    // console.log(tournamentData);
                    const curRound = t.rounds.sort((a,b)=>{return b.number-a.number})[0];
                    const curGame = curRound.games[0];
                    const oldRound = tournamentData.find(x => x.name == key).rounds.sort((a,b)=>{return b.number-a.number})[0];
                    const oldGame = oldRound.games[0];
                    if (curGame.moves.length != oldGame.moves.length) {
                        // console.log(curGame.moves.length + " vs " + oldGame.moves.length);
                        doUpdate = true;
                    }
                } else {
                    // console.log("no tournament data ?");
                    // console.log(tournamentData);
                    doUpdate = true;
                }
                trimmed.push(t);
            }
        }
        setUpdater(true);
        if (doUpdate) {
            // console.log("different data!");
            // console.log(trimmed);
            setTournamentData(trimmed);
        }
        setTimeout(getTournamentData, updateRate);
    }


    const unitStart = 'calc(var(--unit) * ';
    const unitEnd = ')';
    const [showWomens, setShowWomens] = useState(false);
    const border = {
        border: unitStart + .2 + unitEnd + ' solid var(--accent)',
        fontSize: unitStart + 1.5 + unitEnd,
    }
    const noBorder = {
        border: unitStart + .2 + unitEnd + ' solid var(--background)',
        fontSize: unitStart + 1.5 + unitEnd,
    }

    return (
        <div style={{
            marginTop: unitStart + 3 + unitEnd,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
        }}>
            <div style={{
                width: '75%',
                borderBottom: unitStart + .2 + unitEnd + ' solid var(--accent)',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                alignItems: 'center',
            }}>
                <div className='knockout-tabs' style={{
                    margin: '0'
                }}>
                    <button className='knockout-tab-big'
                        style={!showWomens ? border : noBorder}
                        onClick={() => {setShowWomens(false)}}
                    >
                        {'Open'}
                    </button>
                    <button className='knockout-tab-big'
                        style={showWomens ? border : noBorder}
                        onClick={() => {setShowWomens(true)}}
                    >
                        {'Women'}
                    </button>
                </div>
            </div>
            {
                showWomens
                ?
                <KnockoutTournament
                    key={'womens'}
                    name="FIDE Women's World Cup 2023"
                    n={womensWorldCupPlayers.length}
                    players={womensWorldCupPlayers}
                    format={worldCupMatchFormat}
                />
                :
                <KnockoutTournament
                    key={'open'}
                    name="FIDE World Cup 2023"
                    n={worldCupPlayers.length}
                    players={worldCupPlayers}
                    format={worldCupMatchFormat}
                />
            }
            {/* {tournamentData.length == 0 ? 
            <h2 style={{
                textAlign: 'center',
                padding: '20%',
                color: 'white',
            }}>Fetching tournament data...</h2>
             : tournamentData.sort((a, b) => { return miscTournamentData[a.name].order - miscTournamentData[b.name].order; }).map((tournament, i) => (
                <TournamentParent 
                key={i + " " + tournament.name}
                name={tournament.name}
                currentRound={miscTournamentData[tournament.name].currentRound}
                type={miscTournamentData[tournament.name].type}
                comingSoon={miscTournamentData[tournament.name].comingSoon}
                display={miscTournamentData[tournament.name].display}
                playerColors={miscTournamentData[tournament.name].playerColors}
                probInfo={miscTournamentData[tournament.name].probInfo}
                graph={miscTournamentData[tournament.name].graph}
                db={tournament.rounds.find(x => x.number == miscTournamentData[tournament.name].currentRound)}
                rounds={tournament.rounds}
                timeControl={miscTournamentData[tournament.name].timeControl}
                playerInfo={miscTournamentData[tournament.name].playerInfo}
                N={10000}
                />
            ))} */}

            {/* <Tournament 
            name='World Chess Championship 2023'
            subtitle='Game 1'
            type='match'
            comingSoon={true}
            display={true}
            broadcastRoundId='kx9gaE3w'
            playerInfo = {[
                ['Nepo', 2795.0, 0.0, "#938fff"],
                ['Ding', 2788.0, 0.0, "#ff8f8f"],
            ]}
            games = {[
                ['Ding', 'Nepo'],
            ]}
            chances = {[
                [0.21599920898482067, 0.6163473718652897, 0.16759713914788993],
            ]}
            probabilities={wccProbs}
            N={10000}
            depth={depth}
            graph={true}
            /> */}

            {/* <Tournament 
            name='Candidates Tournament 2022'
            subtitle='Finished'
            type='tournament'
            comingSoon={false}
            display={true}
            broadcastRoundId='ZA07lchF'
            playerInfo = {[
                ['Ding', 2806.0, 7.0],
                ['Firouzja', 2793.1, 5.0],
                ['Caruana', 2782.6, 6.5],
                ['Nepo', 2766.4, 9.0],
                ['Rapport', 2764.0, 5.5],
                ['Nakamura', 2760.0, 7.5],
                ['Radjabov', 2748.2, 6.5],
                ['Duda', 2750.0, 5.0],
            ]}
            games = {[
                ['Rapport', 'Radjabov'],
                ['Caruana', 'Firouzja'],
                ['Ding', 'Nakamura'],
                ['Duda', 'Nepo'],
            ]}
            chances = {[
                [0.23466566724939456, 0.6473620483532273, 0.1179722843953783],
                [0.24391905313766132, 0.6488927550214011, 0.10718819183893763],
                [0.2586836675621616, 0.6397994594552858, 0.10151687298055272],
                [0.15867367582238695, 0.6901139190656111, 0.151212405110002],
            ]}
            probabilities={candidatesProbs}
            N={1000}
            depth={depth}
            opacity={30}
            /> */}
        </div>
    )

}