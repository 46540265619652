import React, { useState, useEffect } from 'react';
import Rainbow from 'rainbowvis.js';
import '../App.css';

import adFlag from '../png100px/ad.png';
import aeFlag from '../png100px/ae.png';
import afFlag from '../png100px/af.png';
import agFlag from '../png100px/ag.png';
import aiFlag from '../png100px/ai.png';
import alFlag from '../png100px/al.png';
import amFlag from '../png100px/am.png';
import aoFlag from '../png100px/ao.png';
import aqFlag from '../png100px/aq.png';
import arFlag from '../png100px/ar.png';
import asFlag from '../png100px/as.png';
import atFlag from '../png100px/at.png';
import auFlag from '../png100px/au.png';
import awFlag from '../png100px/aw.png';
import axFlag from '../png100px/ax.png';
import azFlag from '../png100px/az.png';
import baFlag from '../png100px/ba.png';
import bbFlag from '../png100px/bb.png';
import bdFlag from '../png100px/bd.png';
import beFlag from '../png100px/be.png';
import bfFlag from '../png100px/bf.png';
import bgFlag from '../png100px/bg.png';
import bhFlag from '../png100px/bh.png';
import biFlag from '../png100px/bi.png';
import bjFlag from '../png100px/bj.png';
import blFlag from '../png100px/bl.png';
import bmFlag from '../png100px/bm.png';
import bnFlag from '../png100px/bn.png';
import boFlag from '../png100px/bo.png';
import bqFlag from '../png100px/bq.png';
import brFlag from '../png100px/br.png';
import bsFlag from '../png100px/bs.png';
import btFlag from '../png100px/bt.png';
import bvFlag from '../png100px/bv.png';
import bwFlag from '../png100px/bw.png';
import byFlag from '../png100px/by.png';
import bzFlag from '../png100px/bz.png';
import caFlag from '../png100px/ca.png';
import ccFlag from '../png100px/cc.png';
import cdFlag from '../png100px/cd.png';
import cfFlag from '../png100px/cf.png';
import cgFlag from '../png100px/cg.png';
import chFlag from '../png100px/ch.png';
import ciFlag from '../png100px/ci.png';
import ckFlag from '../png100px/ck.png';
import clFlag from '../png100px/cl.png';
import cmFlag from '../png100px/cm.png';
import cnFlag from '../png100px/cn.png';
import coFlag from '../png100px/co.png';
import crFlag from '../png100px/cr.png';
import cuFlag from '../png100px/cu.png';
import cvFlag from '../png100px/cv.png';
import cwFlag from '../png100px/cw.png';
import cxFlag from '../png100px/cx.png';
import cyFlag from '../png100px/cy.png';
import czFlag from '../png100px/cz.png';
import deFlag from '../png100px/de.png';
import djFlag from '../png100px/dj.png';
import dkFlag from '../png100px/dk.png';
import dmFlag from '../png100px/dm.png';
import doFlag from '../png100px/do.png';
import dzFlag from '../png100px/dz.png';
import ecFlag from '../png100px/ec.png';
import eeFlag from '../png100px/ee.png';
import egFlag from '../png100px/eg.png';
import ehFlag from '../png100px/eh.png';
import erFlag from '../png100px/er.png';
import esFlag from '../png100px/es.png';
import etFlag from '../png100px/et.png';
import fiFlag from '../png100px/fi.png';
import fjFlag from '../png100px/fj.png';
import fkFlag from '../png100px/fk.png';
import fmFlag from '../png100px/fm.png';
import foFlag from '../png100px/fo.png';
import frFlag from '../png100px/fr.png';
import gaFlag from '../png100px/ga.png';
import gbFlag from '../png100px/gb.png';
import gdFlag from '../png100px/gd.png';
import geFlag from '../png100px/ge.png';
import gfFlag from '../png100px/gf.png';
import ggFlag from '../png100px/gg.png';
import ghFlag from '../png100px/gh.png';
import giFlag from '../png100px/gi.png';
import glFlag from '../png100px/gl.png';
import gmFlag from '../png100px/gm.png';
import gnFlag from '../png100px/gn.png';
import gpFlag from '../png100px/gp.png';
import gqFlag from '../png100px/gq.png';
import grFlag from '../png100px/gr.png';
import gsFlag from '../png100px/gs.png';
import gtFlag from '../png100px/gt.png';
import guFlag from '../png100px/gu.png';
import gwFlag from '../png100px/gw.png';
import gyFlag from '../png100px/gy.png';
import hkFlag from '../png100px/hk.png';
import hmFlag from '../png100px/hm.png';
import hnFlag from '../png100px/hn.png';
import hrFlag from '../png100px/hr.png';
import htFlag from '../png100px/ht.png';
import huFlag from '../png100px/hu.png';
import idFlag from '../png100px/id.png';
import ieFlag from '../png100px/ie.png';
import ilFlag from '../png100px/il.png';
import imFlag from '../png100px/im.png';
import inFlag from '../png100px/in.png';
import ioFlag from '../png100px/io.png';
import iqFlag from '../png100px/iq.png';
import irFlag from '../png100px/ir.png';
import isFlag from '../png100px/is.png';
import itFlag from '../png100px/it.png';
import jeFlag from '../png100px/je.png';
import jmFlag from '../png100px/jm.png';
import joFlag from '../png100px/jo.png';
import jpFlag from '../png100px/jp.png';
import keFlag from '../png100px/ke.png';
import kgFlag from '../png100px/kg.png';
import khFlag from '../png100px/kh.png';
import kiFlag from '../png100px/ki.png';
import kmFlag from '../png100px/km.png';
import knFlag from '../png100px/kn.png';
import kpFlag from '../png100px/kp.png';
import krFlag from '../png100px/kr.png';
import kwFlag from '../png100px/kw.png';
import kyFlag from '../png100px/ky.png';
import kzFlag from '../png100px/kz.png';
import laFlag from '../png100px/la.png';
import lbFlag from '../png100px/lb.png';
import lcFlag from '../png100px/lc.png';
import liFlag from '../png100px/li.png';
import lkFlag from '../png100px/lk.png';
import lrFlag from '../png100px/lr.png';
import lsFlag from '../png100px/ls.png';
import ltFlag from '../png100px/lt.png';
import luFlag from '../png100px/lu.png';
import lvFlag from '../png100px/lv.png';
import lyFlag from '../png100px/ly.png';
import maFlag from '../png100px/ma.png';
import mcFlag from '../png100px/mc.png';
import mdFlag from '../png100px/md.png';
import meFlag from '../png100px/me.png';
import mfFlag from '../png100px/mf.png';
import mgFlag from '../png100px/mg.png';
import mhFlag from '../png100px/mh.png';
import mkFlag from '../png100px/mk.png';
import mlFlag from '../png100px/ml.png';
import mmFlag from '../png100px/mm.png';
import mnFlag from '../png100px/mn.png';
import moFlag from '../png100px/mo.png';
import mpFlag from '../png100px/mp.png';
import mqFlag from '../png100px/mq.png';
import mrFlag from '../png100px/mr.png';
import msFlag from '../png100px/ms.png';
import mtFlag from '../png100px/mt.png';
import muFlag from '../png100px/mu.png';
import mvFlag from '../png100px/mv.png';
import mwFlag from '../png100px/mw.png';
import mxFlag from '../png100px/mx.png';
import myFlag from '../png100px/my.png';
import mzFlag from '../png100px/mz.png';
import naFlag from '../png100px/na.png';
import ncFlag from '../png100px/nc.png';
import neFlag from '../png100px/ne.png';
import nfFlag from '../png100px/nf.png';
import ngFlag from '../png100px/ng.png';
import niFlag from '../png100px/ni.png';
import nlFlag from '../png100px/nl.png';
import noFlag from '../png100px/no.png';
import npFlag from '../png100px/np.png';
import nrFlag from '../png100px/nr.png';
import nuFlag from '../png100px/nu.png';
import nzFlag from '../png100px/nz.png';
import omFlag from '../png100px/om.png';
import paFlag from '../png100px/pa.png';
import peFlag from '../png100px/pe.png';
import pfFlag from '../png100px/pf.png';
import pgFlag from '../png100px/pg.png';
import phFlag from '../png100px/ph.png';
import pkFlag from '../png100px/pk.png';
import plFlag from '../png100px/pl.png';
import pmFlag from '../png100px/pm.png';
import pnFlag from '../png100px/pn.png';
import prFlag from '../png100px/pr.png';
import psFlag from '../png100px/ps.png';
import ptFlag from '../png100px/pt.png';
import pwFlag from '../png100px/pw.png';
import pyFlag from '../png100px/py.png';
import qaFlag from '../png100px/qa.png';
import reFlag from '../png100px/re.png';
import roFlag from '../png100px/ro.png';
import rsFlag from '../png100px/rs.png';
import ruFlag from '../png100px/ru.png';
import rwFlag from '../png100px/rw.png';
import saFlag from '../png100px/sa.png';
import sbFlag from '../png100px/sb.png';
import scFlag from '../png100px/sc.png';
import sdFlag from '../png100px/sd.png';
import seFlag from '../png100px/se.png';
import sgFlag from '../png100px/sg.png';
import shFlag from '../png100px/sh.png';
import siFlag from '../png100px/si.png';
import sjFlag from '../png100px/sj.png';
import skFlag from '../png100px/sk.png';
import slFlag from '../png100px/sl.png';
import smFlag from '../png100px/sm.png';
import snFlag from '../png100px/sn.png';
import soFlag from '../png100px/so.png';
import srFlag from '../png100px/sr.png';
import ssFlag from '../png100px/ss.png';
import stFlag from '../png100px/st.png';
import svFlag from '../png100px/sv.png';
import sxFlag from '../png100px/sx.png';
import syFlag from '../png100px/sy.png';
import szFlag from '../png100px/sz.png';
import tcFlag from '../png100px/tc.png';
import tdFlag from '../png100px/td.png';
import tfFlag from '../png100px/tf.png';
import tgFlag from '../png100px/tg.png';
import thFlag from '../png100px/th.png';
import tjFlag from '../png100px/tj.png';
import tkFlag from '../png100px/tk.png';
import tlFlag from '../png100px/tl.png';
import tmFlag from '../png100px/tm.png';
import tnFlag from '../png100px/tn.png';
import toFlag from '../png100px/to.png';
import trFlag from '../png100px/tr.png';
import ttFlag from '../png100px/tt.png';
import tvFlag from '../png100px/tv.png';
import twFlag from '../png100px/tw.png';
import tzFlag from '../png100px/tz.png';
import uaFlag from '../png100px/ua.png';
import ugFlag from '../png100px/ug.png';
import umFlag from '../png100px/um.png';
import usFlag from '../png100px/us.png';
import uyFlag from '../png100px/uy.png';
import uzFlag from '../png100px/uz.png';
import vaFlag from '../png100px/va.png';
import vcFlag from '../png100px/vc.png';
import veFlag from '../png100px/ve.png';
import vgFlag from '../png100px/vg.png';
import viFlag from '../png100px/vi.png';
import vnFlag from '../png100px/vn.png';
import vuFlag from '../png100px/vu.png';
import wfFlag from '../png100px/wf.png';
import wsFlag from '../png100px/ws.png';
import xkFlag from '../png100px/xk.png';
import yeFlag from '../png100px/ye.png';
import ytFlag from '../png100px/yt.png';
import zaFlag from '../png100px/za.png';
import zmFlag from '../png100px/zm.png';
import zwFlag from '../png100px/zw.png';

const flagImages = {
    'ad': adFlag,
    'ae': aeFlag,
    'af': afFlag,
    'ag': agFlag,
    'ai': aiFlag,
    'al': alFlag,
    'am': amFlag,
    'ao': aoFlag,
    'aq': aqFlag,
    'ar': arFlag,
    'as': asFlag,
    'at': atFlag,
    'au': auFlag,
    'aw': awFlag,
    'ax': axFlag,
    'az': azFlag,
    'ba': baFlag,
    'bb': bbFlag,
    'bd': bdFlag,
    'be': beFlag,
    'bf': bfFlag,
    'bg': bgFlag,
    'bh': bhFlag,
    'bi': biFlag,
    'bj': bjFlag,
    'bl': blFlag,
    'bm': bmFlag,
    'bn': bnFlag,
    'bo': boFlag,
    'bq': bqFlag,
    'br': brFlag,
    'bs': bsFlag,
    'bt': btFlag,
    'bv': bvFlag,
    'bw': bwFlag,
    'by': byFlag,
    'bz': bzFlag,
    'ca': caFlag,
    'cc': ccFlag,
    'cd': cdFlag,
    'cf': cfFlag,
    'cg': cgFlag,
    'ch': chFlag,
    'ci': ciFlag,
    'ck': ckFlag,
    'cl': clFlag,
    'cm': cmFlag,
    'cn': cnFlag,
    'co': coFlag,
    'cr': crFlag,
    'cu': cuFlag,
    'cv': cvFlag,
    'cw': cwFlag,
    'cx': cxFlag,
    'cy': cyFlag,
    'cz': czFlag,
    'de': deFlag,
    'dj': djFlag,
    'dk': dkFlag,
    'dm': dmFlag,
    'do': doFlag,
    'dz': dzFlag,
    'ec': ecFlag,
    'ee': eeFlag,
    'eg': egFlag,
    'eh': ehFlag,
    'er': erFlag,
    'es': esFlag,
    'et': etFlag,
    'fi': fiFlag,
    'fj': fjFlag,
    'fk': fkFlag,
    'fm': fmFlag,
    'fo': foFlag,
    'fr': frFlag,
    'ga': gaFlag,
    'gb': gbFlag,
    'gd': gdFlag,
    'ge': geFlag,
    'gf': gfFlag,
    'gg': ggFlag,
    'gh': ghFlag,
    'gi': giFlag,
    'gl': glFlag,
    'gm': gmFlag,
    'gn': gnFlag,
    'gp': gpFlag,
    'gq': gqFlag,
    'gr': grFlag,
    'gs': gsFlag,
    'gt': gtFlag,
    'gu': guFlag,
    'gw': gwFlag,
    'gy': gyFlag,
    'hk': hkFlag,
    'hm': hmFlag,
    'hn': hnFlag,
    'hr': hrFlag,
    'ht': htFlag,
    'hu': huFlag,
    'id': idFlag,
    'ie': ieFlag,
    'il': ilFlag,
    'im': imFlag,
    'in': inFlag,
    'io': ioFlag,
    'iq': iqFlag,
    'ir': irFlag,
    'is': isFlag,
    'it': itFlag,
    'je': jeFlag,
    'jm': jmFlag,
    'jo': joFlag,
    'jp': jpFlag,
    'ke': keFlag,
    'kg': kgFlag,
    'kh': khFlag,
    'ki': kiFlag,
    'km': kmFlag,
    'kn': knFlag,
    'kp': kpFlag,
    'kr': krFlag,
    'kw': kwFlag,
    'ky': kyFlag,
    'kz': kzFlag,
    'la': laFlag,
    'lb': lbFlag,
    'lc': lcFlag,
    'li': liFlag,
    'lk': lkFlag,
    'lr': lrFlag,
    'ls': lsFlag,
    'lt': ltFlag,
    'lu': luFlag,
    'lv': lvFlag,
    'ly': lyFlag,
    'ma': maFlag,
    'mc': mcFlag,
    'md': mdFlag,
    'me': meFlag,
    'mf': mfFlag,
    'mg': mgFlag,
    'mh': mhFlag,
    'mk': mkFlag,
    'ml': mlFlag,
    'mm': mmFlag,
    'mn': mnFlag,
    'mo': moFlag,
    'mp': mpFlag,
    'mq': mqFlag,
    'mr': mrFlag,
    'ms': msFlag,
    'mt': mtFlag,
    'mu': muFlag,
    'mv': mvFlag,
    'mw': mwFlag,
    'mx': mxFlag,
    'my': myFlag,
    'mz': mzFlag,
    'na': naFlag,
    'nc': ncFlag,
    'ne': neFlag,
    'nf': nfFlag,
    'ng': ngFlag,
    'ni': niFlag,
    'nl': nlFlag,
    'no': noFlag,
    'np': npFlag,
    'nr': nrFlag,
    'nu': nuFlag,
    'nz': nzFlag,
    'om': omFlag,
    'pa': paFlag,
    'pe': peFlag,
    'pf': pfFlag,
    'pg': pgFlag,
    'ph': phFlag,
    'pk': pkFlag,
    'pl': plFlag,
    'pm': pmFlag,
    'pn': pnFlag,
    'pr': prFlag,
    'ps': psFlag,
    'pt': ptFlag,
    'pw': pwFlag,
    'py': pyFlag,
    'qa': qaFlag,
    're': reFlag,
    'ro': roFlag,
    'rs': rsFlag,
    'ru': ruFlag,
    'rw': rwFlag,
    'sa': saFlag,
    'sb': sbFlag,
    'sc': scFlag,
    'sd': sdFlag,
    'se': seFlag,
    'sg': sgFlag,
    'sh': shFlag,
    'si': siFlag,
    'sj': sjFlag,
    'sk': skFlag,
    'sl': slFlag,
    'sm': smFlag,
    'sn': snFlag,
    'so': soFlag,
    'sr': srFlag,
    'ss': ssFlag,
    'st': stFlag,
    'sv': svFlag,
    'sx': sxFlag,
    'sy': syFlag,
    'sz': szFlag,
    'tc': tcFlag,
    'td': tdFlag,
    'tf': tfFlag,
    'tg': tgFlag,
    'th': thFlag,
    'tj': tjFlag,
    'tk': tkFlag,
    'tl': tlFlag,
    'tm': tmFlag,
    'tn': tnFlag,
    'to': toFlag,
    'tr': trFlag,
    'tt': ttFlag,
    'tv': tvFlag,
    'tw': twFlag,
    'tz': tzFlag,
    'ua': uaFlag,
    'ug': ugFlag,
    'um': umFlag,
    'us': usFlag,
    'uy': uyFlag,
    'uz': uzFlag,
    'va': vaFlag,
    'vc': vcFlag,
    've': veFlag,
    'vg': vgFlag,
    'vi': viFlag,
    'vn': vnFlag,
    'vu': vuFlag,
    'wf': wfFlag,
    'ws': wsFlag,
    'xk': xkFlag,
    'ye': yeFlag,
    'yt': ytFlag,
    'za': zaFlag,
    'zm': zmFlag,
    'zw': zwFlag,
  };


export default function KnockoutBracket(props) {

    const unitStart = 'calc(var(--unit) * ';
    const unitEnd = ')';
    const rainbow = new Rainbow();
    rainbow.setSpectrum('FF4444', 'FFFF44', '#44FF44');

    const o = unitStart + .1 + unitEnd;
    const no = unitStart + -.1 + unitEnd;

    const [area, setArea] = useState({
        height: 200,
        width: 120,
    })
    const minHeight = 50;
    const maxHeight = 200;

    const textSize = .85;

    const linethickness = .2;
    const finalOffset = .0;

    const [bracketSize, setBracketSize] = useState(getBracketSize(props.n))
    const [seedings, setSeedings] = useState(getSeedings(getBracketSize(props.n)));

    const [currentPlayer, setCurrentPlayer] = useState(props.players[0]);

    const [editedChancesMatrix, setEditedChancesMatrix] = useState([...props.chancesMatrix.map((x) => [...x])]);

    const [selectedPicks, setSelectedPicks] = useState(props.players.map((x, i) => {
        return {
            makeItPast: -1,
            cantPass: [],
        }
    }));

    function getBracketSize(numPlayers) {
        return Math.pow(2, Math.ceil(Math.log2(numPlayers)));
    }

    function getSeedings(numPlayers) {
        var rounds = Math.log(numPlayers)/Math.log(2)-1;
        var pls = [1,2];
        for(var i=0;i<rounds;i++){
            pls = nextLayer(pls);
        }
        return pls;
        function nextLayer(pls){
            var out=[];
            var length = pls.length*2+1;
            pls.forEach(function(d){
            out.push(d);
            out.push(length-d);
            });
            return out;
        }
    }

    function getCurrentPlayerSeed() {
        var currentPlayerSeed = -1;
        for (var j = 0; j < props.players.length; j++) {
            if (props.players[j].name == currentPlayer.name) currentPlayerSeed = j + 1;
        }
        return currentPlayerSeed;
    }
    
    const updateChancesMatrix = (seed, round) => {
        var picks = selectedPicks.slice();
        var opponents = props.findPossibleOpponents(seed, round);
        var fellows = props.findPossibleOpponents(opponents[0] + 1, round);
        if (picks[seed - 1].makeItPast >= round) {
            while (picks[seed - 1].makeItPast >= round) {
                opponents = props.findPossibleOpponents(seed, picks[seed - 1].makeItPast);
                fellows = props.findPossibleOpponents(opponents[0] + 1, picks[seed - 1].makeItPast);
                fellows.concat(opponents).forEach((i) => {
                    if (i != seed - 1 && picks[i].cantPass.includes(picks[seed - 1].makeItPast)) {
                        const remIndex = picks[i].cantPass.indexOf(picks[seed - 1].makeItPast);
                        picks[i].cantPass.splice(remIndex, 1);
                    }
                })
                picks[seed - 1].makeItPast--;
            }
        } else {
            fellows.concat(opponents).forEach((i) => {
                if (i != seed - 1) {
                    if (!picks[i].cantPass.includes(round)) picks[i].cantPass.push(round);
                    while (picks[i].makeItPast >= round) {
                        const opps = props.findPossibleOpponents(i + 1, picks[i].makeItPast);
                        const fels = props.findPossibleOpponents(opps[0] + 1, picks[i].makeItPast);
                        opps.concat(fels).forEach((j) => {
                            if (j != i && picks[j].cantPass.includes(picks[i].makeItPast)) {
                                const remIndexx = picks[j].cantPass.indexOf(picks[i].makeItPast);
                                picks[j].cantPass.splice(remIndexx, 1);
                            }
                        })
                        picks[i].makeItPast--;
                    }
                }
            })
            if (picks[seed - 1].cantPass.includes(round)) {
                const remIndex = picks[seed - 1].cantPass.indexOf(round);
                picks[seed - 1].cantPass.splice(remIndex, 1);
            }
            picks[seed - 1].makeItPast = round;
        }
        setSelectedPicks(picks);
        setEditedChancesMatrix([...props.createChancesMatrix(picks)]);
    }

    return (
        <div style={{
            margin: unitStart + 1 + unitEnd,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div style={{margin: '0 auto'}}>
                <input className='bracket-zoom'
                    type='range'
                    min={minHeight}
                    max={maxHeight}
                    value={area.height}
                    onChange={(e) => setArea({height: e.target.valueAsNumber, width: area.width})}
                    style={{
                        backgroundSize: `${100 * (area.height - minHeight) / (maxHeight - minHeight)}% 100%`
                    }}
                >
                </input>
                {/* <p style={{position: 'absolute', color: 'pink'}}></p> */}
            </div>
            <div className='bracket-container'
            style={{
                height: unitStart + area.height + unitEnd,
                width: unitStart + area.width + unitEnd,
            }}> 
                {Array.from({length: bracketSize - 1}, (item, i) => 
                    {
                        const size = bracketSize / 2;
                        const column = (Math.log2(size) + 1) - Math.ceil(Math.log2(2 * size - i))
                        const sizeIncreaseByColumn = x => .5 * x + 1;
                        const sizeIncrease = sizeIncreaseByColumn(column);
                        const prevSizeIncrease = sizeIncreaseByColumn(column-1);
                        const x = column / (Math.log2(size) + 1)
                        const base = size * 2 - Math.pow(2, Math.ceil(Math.log2(2 * size - i)))
                        const spacing = Math.pow(2, column) / size;
                        const offset = (Math.pow(2, column - 1) - .5) / size;
                        const y = (i - base) * spacing + offset;
                        const linelength = column > 0 ? Math.pow(2, column - 1) / size : 0;
                        const slotWidth = .5 * (100.0 / (Math.log2(size) + 1));
                        const slotHeight = (100.0 / (size));
                        
                        var seed = i < bracketSize / 2 ? seedings[i] : -1;
                        if (seed == -1) {
                            const i0 = 2 * (i - bracketSize / 2);
                            const i1 = 2 * (i - bracketSize / 2) + 1;
                            var pastSeeds = [
                                i0 < bracketSize / 2 ? seedings[i0] : -1,
                                i1 + 1 < bracketSize / 2 ? seedings[i1] : -1,
                            ]
                            if (pastSeeds[0] <= props.n && pastSeeds[1] > props.n) seed = pastSeeds[0];
                            if (pastSeeds[1] <= props.n && pastSeeds[0] > props.n) seed = pastSeeds[1];
                        }
                        var opponentSeed = i < bracketSize / 2 ? seedings[i + (i % 2) * -2 + 1] : -1;

                        var player = seed > 0 && seed <= props.n ? props.players[seed-1] : null;

                        // var currentPlayerIndex = props.players.indexOf(currentPlayer);
                        var currentPlayerSeed = -1;
                        for (var j = 0; j < props.players.length; j++) {
                            if (props.players[j].name == currentPlayer.name) currentPlayerSeed = j + 1;
                        }
                        
                        var numChildren = Math.pow(2, column);
                        var t = Math.pow(1/2, column) * bracketSize / 2
                        var firstChildIndex = ((i % t) / t) * bracketSize / 2;
                        var firstChildSeed = seedings[firstChildIndex];
                        var currentPlayerIsChild = false;
                        var topHalf = false;
                        var selected = false;
                        for (var j = 0; j < numChildren; j++) {
                            if (currentPlayerSeed == seedings[firstChildIndex + j]) {
                                if (j < numChildren / 2) topHalf = true;
                                currentPlayerIsChild = true;
                            }
                            const ind = seedings[firstChildIndex + j] - 1;
                            if (ind < props.players.length && editedChancesMatrix[ind][column + 1] == 1) {
                                player = props.players[seedings[firstChildIndex + j] - 1];
                                seed = ind + 1;
                                selected = editedChancesMatrix[ind][column + 1] != props.chancesMatrix[ind][column + 1];
                            }
                        }
                        var clickable = player != null;
                        if (currentPlayerIsChild && !selected) {
                            seed = currentPlayerSeed;
                            player = currentPlayer;
                        }

                        var percentage = (currentPlayerIsChild || selected) ? 100 * Math.round(editedChancesMatrix[currentPlayerSeed - 1][column + 1] * 10000) / 10000 : -1;

                        var opacity = seed > 0 ? seed <= props.n && opponentSeed <= props.n ? '' : 'opacity(0%)' : clickable ? '' : 'opacity(50%)';
                        if (opacity != 'opacity(0%)' && !clickable) opacity = 'opacity(50%)'

                        return (
                            <div key={i + " " + percentage}>
                                <div className='bracket-line'
                                    style={{...{
                                        backgroundImage: currentPlayerIsChild ? topHalf ? 'linear-gradient(var(--accent) 0%, var(--light-background) 80%)' : 'linear-gradient(var(--light-background) 20%, var(--accent) 100%)' : '',
                                        height: (linelength * 100 + prevSizeIncrease * 100 / size) + '%',
                                        width: unitStart + linethickness + unitEnd,
                                        transform: 'translate(' + unitStart + (x * (area.width / 2) - linethickness / 2) + unitEnd + ', ' + unitStart + (y * area.height - (prevSizeIncrease * slotHeight * area.height / 100 - slotHeight * area.height / 100) / 2 - (linelength * area.height) / 2) + unitEnd + ')',
                                        filter: opacity,
                                    }}}
                                >
                                </div>
                                <div className='bracket-slot'
                                    style={{...{
                                        backgroundImage: player ? 'url(' + flagImages[player.countryCode.toLowerCase()] + ')' : '',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: sizeIncrease * slotHeight + '%',
                                        width: slotWidth + '%',
                                        transform: 'translate(' + unitStart + (x * (area.width / 2)) + unitEnd + ', calc(' + unitStart + (y * area.height - (sizeIncrease * slotHeight * area.height / 100 - slotHeight * area.height / 100) / 2 + ((i == bracketSize - 2) ? finalOffset * linelength * area.height / 2 : 0)) + unitEnd + ((i == bracketSize - 2) ? ' + 200%' : ' + 0%') + '))',
                                        filter: opacity + (selected ? ' contrast(100%) saturate(100%)' : ''),
                                        outline: 'calc(var(--unit) * .2) ' + 'solid' + ' ' + (currentPlayerIsChild ? 'var(--accent)' : selected ? 'var(--accent)' : 'var(--light-background)'),
                                        pointerEvents: (!clickable ? 'none' : '')
                                    }}}
                                    onMouseEnter={() => {
                                        if (player != null) setCurrentPlayer(player);
                                    }}
                                    onClick={() => {
                                        if (clickable) {
                                            console.log(seed + " , " + column)
                                            updateChancesMatrix(seed, column);
                                        }
                                    }}
                                >
                                    <p className='knockout-name'
                                        style = {{
                                            fontSize: unitStart + textSize + unitEnd,
                                            pointerEvents: 'none',
                                        }}
                                    >
                                    {player ? seed + ' ' + player.name : currentPlayerIsChild ? currentPlayer.name : ''}
                                    </p>
                                </div>
                                <div style={{
                                    height: sizeIncrease * slotHeight + '%',
                                    width: slotWidth + '%',
                                    transform: 'translate(' + unitStart + (x * (area.width / 2)) + unitEnd + ', calc(' + unitStart + (y * area.height - (sizeIncrease * slotHeight * area.height / 100 - slotHeight * area.height / 100) / 2 + ((i == bracketSize - 2) ? finalOffset * linelength * area.height / 2 : 0)) + unitEnd + ((i == bracketSize - 2) ? ' + 200%' : ' + 0%') + '))',
                                    filter: opacity,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    pointerEvents: 'none',
                                    filter: opacity=='opacity(0%)' ? opacity : 'opacity(5000%)',
                                }}>
                                    <p className='probability-pop-up'
                                        style = {{
                                            fontSize: unitStart + textSize * 2 + unitEnd,
                                            transform: 'translate(0%, -100%)',
                                            color: '#' + rainbow.colorAt(percentage),
                                        }}
                                    >
                                        {currentPlayerIsChild ? percentage + '%' : ''}
                                    </p>
                                </div>
                            </div>
                        )
                    }
                )}
                {Array.from({length: bracketSize - 1}, (item, i) => 
                    {
                        const size = bracketSize / 2;
                        const numColumns = Math.log2(size) + 1;
                        const column = numColumns - Math.ceil(Math.log2(2 * size - i))
                        const sizeIncreaseByColumn = x => .5 * x + 1;
                        const sizeIncrease = sizeIncreaseByColumn(column);
                        const prevSizeIncrease = sizeIncreaseByColumn(column-1);
                        const x = (numColumns - column) / numColumns
                        const base = size * 2 - Math.pow(2, Math.ceil(Math.log2(2 * size - i)))
                        const spacing = Math.pow(2, column) / size;
                        const offset = (Math.pow(2, column - 1) - .5) / size;
                        const y = (i - base) * spacing + offset;
                        const linelength = column > 0 ? Math.pow(2, column - 1) / size : 0;
                        const slotWidth = .5 * (100.0 / (Math.log2(size) + 1));
                        const slotHeight = (100.0 / (size));

                        var seed = i < bracketSize / 2 ? seedings[i + bracketSize / 2] : -1;
                        if (seed == -1) {
                            const i0 = 2 * (i - bracketSize / 2);
                            const i1 = 2 * (i - bracketSize / 2) + 1;
                            var pastSeeds = [
                                i0 < bracketSize / 2 ? seedings[i0 + bracketSize / 2] : -1,
                                i1 + 1 < bracketSize / 2 ? seedings[i1 + bracketSize / 2] : -1,
                            ]
                            if (pastSeeds[0] <= props.n && pastSeeds[1] > props.n) seed = pastSeeds[0];
                            if (pastSeeds[1] <= props.n && pastSeeds[0] > props.n) seed = pastSeeds[1];
                        }
                        var opponentSeed = i < bracketSize / 2 ? seedings[i + (i % 2) * -2 + 1] : -1;
                        
                        var player = seed > 0 && seed <= props.n ? props.players[seed-1] : null;

                        // var currentPlayerIndex = props.players.indexOf(currentPlayer);
                        var currentPlayerSeed = -1;
                        for (var j = 0; j < props.players.length; j++) {
                            if (props.players[j].name == currentPlayer.name) currentPlayerSeed = j + 1;
                        }
                        
                        var numChildren = Math.pow(2, column);
                        var t = Math.pow(1/2, column) * bracketSize / 2
                        var firstChildIndex = ((i % t) / t) * bracketSize / 2 + bracketSize / 2;
                        var firstChildSeed = seedings[firstChildIndex];
                        var currentPlayerIsChild = false;
                        var topHalf = false;
                        var selected = false;
                        for (var j = 0; j < numChildren; j++) {
                            if (currentPlayerSeed == seedings[firstChildIndex + j]) {
                                if (j < numChildren / 2) topHalf = true;
                                currentPlayerIsChild = true;
                            }
                            const ind = seedings[firstChildIndex + j] - 1;
                            if (ind < props.players.length && editedChancesMatrix[ind][column + 1] == 1) {
                                player = props.players[seedings[firstChildIndex + j] - 1];
                                seed = ind + 1;
                                selected = editedChancesMatrix[ind][column + 1] != props.chancesMatrix[ind][column + 1];
                            }
                        }
                        var clickable = player != null;
                        if (currentPlayerIsChild) {
                            seed = currentPlayerSeed;
                            player = currentPlayer;
                        }

                        var percentage = (currentPlayerIsChild || selected) ? 100 * Math.round(editedChancesMatrix[currentPlayerSeed - 1][column + 1] * 10000) / 10000 : -1;

                        var opacity = seed > 0 ? seed <= props.n && opponentSeed <= props.n ? '' : 'opacity(0%)' : clickable ? '' : 'opacity(50%)';
                        if (opacity != 'opacity(0%)' && !clickable) opacity = 'opacity(50%)'
                        
                        return (
                            <div key={i}>
                                <div className='bracket-line'
                                    style={{...{
                                        backgroundImage: currentPlayerIsChild ? topHalf ? 'linear-gradient(var(--accent) 0%, var(--light-background) 80%)' : 'linear-gradient(var(--light-background) 20%, var(--accent) 100%)' : '',
                                        height: (linelength * 100 + prevSizeIncrease * 100 / size) + '%',
                                        width: unitStart + linethickness + unitEnd,
                                        transform: 'translate(' + unitStart + (x * (area.width / 2) + area.width / 2 - linethickness / 2) + unitEnd + ', ' + unitStart + (y * area.height - (prevSizeIncrease * slotHeight * area.height / 100 - slotHeight * area.height / 100) / 2 - (linelength * area.height) / 2 - 0) + unitEnd + ')',
                                        filter: opacity,
                                    }}}
                                >
                                </div>
                                <div className='bracket-slot'
                                    style={{...{
                                        backgroundImage: player ? 'url(' + flagImages[player.countryCode.toLowerCase()] + ')' : '',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: sizeIncrease * slotHeight + '%',
                                        width: slotWidth + '%',
                                        transform: 'translate(calc(' + unitStart + (x * (area.width / 2) + area.width / 2) + unitEnd + ' - 100%), calc(' + unitStart + (y * area.height - (sizeIncrease * slotHeight * area.height / 100 - slotHeight * area.height / 100) / 2 - ((i == bracketSize - 2) ? finalOffset * linelength * area.height / 2 : 0)) + unitEnd + ((i == bracketSize - 2) ? ' - 200%' : ' + 0%') + '))',
                                        filter: opacity + (selected ? ' contrast(100%) saturate(100%)' : ''),
                                        outline: 'calc(var(--unit) * .2) ' + 'solid' + ' ' + (currentPlayerIsChild ? 'var(--accent)' : selected ? 'var(--accent)' : 'var(--light-background)'),
                                        pointerEvents: (!clickable ? 'none' : '')
                                    }}}
                                    onMouseEnter={() => {
                                        if (player != null) setCurrentPlayer(player);
                                    }}
                                    onClick={() => {
                                        if (clickable) {
                                            updateChancesMatrix(seed, column);
                                        }
                                    }}
                                > 
                                    <p className='knockout-name'
                                        style = {{
                                            fontSize: unitStart + textSize + unitEnd,
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        {player ? seed + ' ' + player.name : currentPlayerIsChild ? currentPlayer.name : ''}
                                    </p>
                                </div>
                                <div style={{
                                    height: sizeIncrease * slotHeight + '%',
                                    width: slotWidth + '%',
                                    transform: 'translate(calc(' + unitStart + (x * (area.width / 2) + area.width / 2) + unitEnd + ' - 100%), calc(' + unitStart + (y * area.height - (sizeIncrease * slotHeight * area.height / 100 - slotHeight * area.height / 100) / 2 - ((i == bracketSize - 2) ? finalOffset * linelength * area.height / 2 : 0)) + unitEnd + ((i == bracketSize - 2) ? ' - 200%' : ' + 0%') + '))',
                                    filter: opacity,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    pointerEvents: 'none',
                                    filter: opacity=='opacity(0%)' ? opacity : 'opacity(5000%)',
                                }}>
                                    <p className='probability-pop-up'
                                        style = {{
                                            fontSize: unitStart + textSize * 2 + unitEnd,
                                            transform: 'translate(0%, -100%)',
                                            color: '#' + rainbow.colorAt(percentage),
                                        }}
                                    >
                                        {currentPlayerIsChild ? percentage + '%' : ''}
                                    </p>
                                </div>
                            </div>
                        )
                    }
                )}
                <div className='bracket-slot'
                    style={{...{
                        backgroundImage: currentPlayer ? 'url(' + flagImages[currentPlayer.countryCode.toLowerCase()] + ')' : '',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: (.5 * (Math.log2(bracketSize / 2) + 1) + 1) * (150.0 / (bracketSize / 2)) + '%',
                        width: .5 * (150.0 / (Math.log2(bracketSize / 2) + 1)) + '%',
                        transform: 'translate(calc(' + unitStart + (area.width / 2) + unitEnd + ' - 50%), calc(' + unitStart + (area.height / 2) + unitEnd + ' - 50%))',
                        filter: currentPlayer ? '' : 'opacity(50%)',
                        outline: 'calc(var(--unit) * .2) solid ' + (currentPlayer ? 'var(--accent)' : 'var(--light-background)'),
                    }}}
                >
                    <p className='knockout-name'
                        style = {{
                            fontSize: unitStart + textSize * 1.5 + unitEnd,
                        }}
                    >
                        {currentPlayer ? currentPlayer.name : ''}
                    </p>
                </div>
                <div style={{
                    height: (.5 * (Math.log2(bracketSize / 2) + 1) + 1) * (150.0 / (bracketSize / 2)) + '%',
                    width: .5 * (150.0 / (Math.log2(bracketSize / 2) + 1)) + '%',
                    transform: 'translate(calc(' + unitStart + (area.width / 2) + unitEnd + ' - 50%), calc(' + unitStart + (area.height / 2) + unitEnd + ' - 50%))',
                    filter: currentPlayer ? '' : 'opacity(50%)',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pointerEvents: 'none'
                }}>
                    <p className='probability-pop-up'
                        style = {{
                            fontSize: unitStart + textSize * 2 + unitEnd,
                            transform: 'translate(0%, -100%)',
                            color: !currentPlayer ? 'white' : '#' + rainbow.colorAt(100 * Math.round(editedChancesMatrix[getCurrentPlayerSeed() - 1][editedChancesMatrix[0].length - 1] * 10000) / 10000),
                        }}
                    >
                        {currentPlayer ? 100 * Math.round(editedChancesMatrix[getCurrentPlayerSeed() - 1][editedChancesMatrix[0].length - 1] * 10000) / 10000 + '%' : ''}
                    </p>
                </div>
            </div>
        </div>
    )
}