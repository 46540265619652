import React from 'react'
import fischer from '../Images/fischerasciiscreenshot.png'
import axios from 'axios'
import qs from 'qs'
import ReactGA from 'react-ga'

export default function AboutPage() {

    const comingSoon = false;

    return (
        <div className='about'>
            {comingSoon ? 
            <h2>
                coming soon
            </h2> :
            <div>
                <h2>Welcome to Chess Assess!</h2>
                <h4 className='desc'>
                    a chess website for training and following games made by Johnathan Browning
                    {'\n\n'}<a onClick={() => {
                        ReactGA.event({
                            category: 'About',
                            action: 'Clicked Twitter Link'
                        });
                    }} className='twitter-link' target="_blank" rel="noopener noreferrer" href="https://twitter.com/JBrowningIndie">@JBrowningIndie</a>
                </h4>
                <h3>
                    About Chess Assess
                </h3>
                <p>
                    This is a side project of mine to host everything I want to build related to chess. 
                </p>
                <p>
                    I made this site with React.js, with the backend powered by Node.js using MongoDB, hosted on Heroku.
                </p>
                <p>
                    The 
                    {'\n\n'}<a onClick={() => {
                        ReactGA.event({
                            category: 'About',
                            action: 'Clicked Lichess API Link'
                        });
                    }} className='twitter-link general-link' target="_blank" rel="noopener noreferrer" href="https://lichess.org/api">{' Lichess.org API '}</a>
                    has been incredibly helpful for this project, for both accessing the database for my Opening Trainer, and following Live broadcasts for following tournaments. 
                </p>
                <h3>
                    About My Candidates Prediction Model
                </h3>
                <p>
                    I'll go ahead and preface this by saying I am not a statistician or data analyst.
                    I'm just a software engineer who enjoys chess, programming and stats.
                    Don't take all of this too seriously; I just do this stuff for fun and I hope it will make your chess experience more enjoyable!
                </p>
                <p>
                    You can follow the 2024 FIDE Candidates Tournament LIVE at 
                    <a onClick={() => {
                        ReactGA.event({
                            category: 'About',
                            action: 'Clicked Candidates Link'
                        });
                    }} className='twitter-link general-link' target="_blank" rel="noopener noreferrer" href="https://chessassess.com/candidates">{' this page'}</a>
                    . It's unique in that not only do I run simulations between rounds to predict the tournament winner, 
                    but I also am actively predicting the result of each game as it's being played, move-by-move. 
                    These models are then combined together to get live-updating tournament victory chances every move of every game.
                </p>
                <h5>
                    Simulations
                </h5>
                <p>
                    This part isn't new, people (I included) have run simulations for the Candidates for some time now. Check out 
                    <a onClick={() => {
                        ReactGA.event({
                            category: 'About',
                            action: 'Clicked Chess Numbers Link'
                        });
                    }} className='twitter-link general-link' target="_blank" rel="noopener noreferrer" href="https://twitter.com/ChessNumbers ">{' @ChessNumbers '}</a>
                    and 
                    <a onClick={() => {
                        ReactGA.event({
                            category: 'About',
                            action: 'Clicked Pawnalyze Link'
                        });
                    }} className='twitter-link general-link' target="_blank" rel="noopener noreferrer" href="https://twitter.com/Pawnalyze">{' @Pawnalyze '}</a>
                    to see some other simulation models. 
                </p>
                <p>
                    To calculate win, draw, loss percentages for two players in my simulations I use the logistic regression model I explain below, with inputs for the starting position of the game.
                </p>
                <h5>
                    Predicting Games Live
                </h5>
                <p>
                    In order to accomplish live updating W/D/L chances, I used a multinomial logistic regression model. 
                    I trained the model on positions from past Candidates Tournaments, using key information about each individual position: 
                    the engine's evaluation, move number, ratings of the players, and their clock times.
                </p>
                <p>
                    All that was left to do now was combine the two models and update everything every time a move is played, and allow the user to plug in different scenarios to see the outcome. 
                    Instead of constantly re-running simulations, I run 81 different sets of simulations, for every combination of result for each of the 4 games: win, draw, loss. 
                    I can then use the live-updating W/D/L probabilities in combination with the corresponding simulations to output live updating tournament victory chances.
                </p>
                
            </div>
            }
        </div>
    )
}